import { fetch_post } from "../services/common";
import jwt_decode from "jwt-decode";

const API_URL = "https://dev.svsf-ta2.se/api/auth/";
const register = (username, email, password) => {
  return fetch(API_URL + "signup", 
            {   
                method: 'POST',
                body: JSON.stringify({ username: username, email: email, password: password})
            }    
    );
};

async function login  (username, password) {

  return fetch_post('auth/signin', { username: username, password: password })
            .then((data) => {
                if (data.status=='ok') {
                    return data.data;
                } else {
                    throw new Error(data.data.error_msg);
                }
                
            })};  

async function logout() {
  localStorage.removeItem("key_id");
  localStorage.removeItem("token");
  return true;
};

async function getCurrentUserName() {
  
  const data = localStorage.getItem("token");

  if (data) {
    const decoded = jwt_decode(data);
    return decoded.sub;
  } else {
    return "";
  }
  
};

async function getCurrentUserKID() {

  const data = localStorage.getItem("token");

  if (data) {
    const decoded = jwt_decode(data);
    return decoded.kid;
  } else {
    return "";
  }
  
};

async function is_loggedin() {

  const data = localStorage.getItem("token");

  if (data) {
    const decoded = jwt_decode(data);
    return true;
  } else {
    return false;
  }
  
};

const AuthService = {
  register,
  login,
  logout,
  getCurrentUserName,
  getCurrentUserKID,
  is_loggedin
}

export default AuthService;
