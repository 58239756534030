import { fetch_json } from "../../../../services/common";


export async function AddSkyttekort(userKey,sportsVal,skyttekortNumber){

	const date = new Date();

	/* let day = date.getDate();
	let month = date.getMonth() + 1;
	let year = date.getFullYear();

	let currentDate = `${year}-${month}-${day}`; */

	let currentDate = new Date().toJSON().slice(0, 10);
	console.log(currentDate);

	const response = await fetch("https://dev.svsf-ta.se/api/app/user/do_submit/" + userKey, {
	//headers: headers,
	method: 'POST',
	body: JSON.stringify({
		"action": "add_skyttekort",
		"db_sportgren_id": sportsVal,
		"db_skyttekort_nummer": skyttekortNumber,
		"db_skyttekort_datum": currentDate
	})
		

	});
	
	if (!response.ok) {
		//throw new Error(
		 // `This is an HTTP error: The status is ${response.status}`
		//);
	}    
	const obj = await response.json();
	return  obj;
}