import React, { useEffect,useState } from 'react';
import {    
	MDBPopconfirm,
	MDBPopconfirmMessage,
	MDBRow,
	MDBCol,
	MDBModal,
	MDBModalBody,
	MDBModalContent,
	MDBModalTitle,
	MDBModalFooter,
	MDBModalHeader,
	MDBModalDialog,
	MDBBtn,
	MDBAlert,
	MDBSpinner,
	MDBListGroup,
	MDBListGroupItem,
	MDBBadge
 } from 'mdb-react-ui-kit';
 import { fetch_get } from '../../../../services/common';
 import { AddLicenseMulti } from '../../license/service/AddLicenseMulti';

export default function KansliLicenseModal(params){

	const users = params.sentUsers;
	const [licenseAdded, setLicenseAdded] = useState(false)
	const [licenseRemoved, setLicenseRemoved] = useState(false)
	const [licenseError, setLicenseError] = useState(false)
	const [loading, setLoading] = useState(false)
	const [usersDetailed, setUsersDetailed] = useState(null)

	const addLicense = async () => {

		if(usersDetailed){
			setLoading(true)
			AddLicenseMulti(usersDetailed).then(obj => {
				if(obj.status === "ok"){
					setLicenseAdded((prev) => !prev)
					setLoading(false)
				} else {
					setLicenseError((prev) => !prev)
					setLoading(false)
				}
			});
		}
		
	};

	useEffect(() => {
		const key_arr = []
		users.map(user => {
			key_arr.push(user.id)
		})
		setLoading(true)
		fetch_get("data/users_lic/?_filter=key_id IN (" + '"' + key_arr.join('", "') + '"' + ")")
			.then((data) =>
				setTimeout(() => {
					setUsersDetailed(data.data)
				}, setLoading(false)
				,2000),
			);
	}, []);

	return <>
					<MDBAlert
						color='primary'
						autohide
						position='top-right'
						delay={4000}
						appendToBody
						open={licenseAdded}
						onClose={() => setLicenseAdded(false)}
					>
						Licens har lagts till.
					</MDBAlert>
					<MDBAlert
						color='danger'
						autohide
						position='top-right'
						delay={4000}
						appendToBody
						open={licenseError}
						onClose={() => setLicenseError(false)}
					>
						Licens kunde inte läggas till.
					</MDBAlert>
					<MDBAlert
						color='primary'
						autohide
						position='top-right'
						delay={4000}
						appendToBody
						open={licenseRemoved}
						onClose={() => setLicenseRemoved(false)}
					>
						Licens har tagits bort.
					</MDBAlert>
					<MDBModal onClose={params.close} open={params.show} tabIndex='-1'>
        		<MDBModalDialog size="lg">
							<MDBModalContent>
								<MDBModalHeader>
									<MDBModalTitle>Hantera licens</MDBModalTitle>
									<MDBBtn className='btn-close' color='none' onClick={params.close}></MDBBtn>
								</MDBModalHeader>
								<MDBModalBody>
									<MDBRow>
										<MDBCol md={6}>
										<h5>Valda användare</h5>
										<MDBListGroup style={{ minWidth: '12rem' }} light small>												
											{users.map(user => {
												return <MDBListGroupItem className='d-flex justify-content-between align-items-center' key={user.original.user_key_id}>
																<div>
																	<div className='fw-bold'>{user.original.user_fullname}</div>
																</div>
																<MDBBadge pill light color='primary'>
																	IID-nr: {user.original.iid_nr}
																</MDBBadge>
															</MDBListGroupItem>
											})}									
										</MDBListGroup>
										</MDBCol>
										<MDBCol className='text-end'>
											<MDBPopconfirm modal btnChildren={loading ? <MDBSpinner size='sm' role='status' tag='span' /> : 'Lägg till licens'} onConfirm={() => addLicense()}>
												<MDBPopconfirmMessage>Lägg till 12-månaders licens för valda användare?</MDBPopconfirmMessage>
											</MDBPopconfirm>
										</MDBCol>
									</MDBRow>
							</MDBModalBody>
            <MDBModalFooter>
              <MDBBtn color='secondary' onClick={params.close}>
                Stäng
              </MDBBtn>
            </MDBModalFooter>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>
					
        </>
  
}