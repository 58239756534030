import React, { useState, useEffect } from 'react';
import { useLoaderData } from 'react-router-dom';
import {
	MDBRow,
	MDBCol,
	MDBContainer,
	MDBValidation,
	MDBValidationItem,
	MDBInput,
	MDBBtn,
	MDBAlert,
	MDBModal,
	MDBModalDialog,
	MDBModalContent,
	MDBModalHeader,
	MDBModalTitle,
	MDBModalBody,
	MDBModalFooter,
	MDBSelect,
	MDBAutocomplete
} from 'mdb-react-ui-kit';
import { BorderCol } from '../modules/BorderCol';
import { fetch_post, fetch_get } from '../../services/common';

export default function ResetPassword() {

	const [input, setInput] = useState(null);
	const [openAlert, setOpenAlert] = useState(false);
	const [alertMsg, setAlertMsg] = useState("");
	const [alertColor, setAlertColor] = useState('warning');
	const [modalOpen, setModalOpen] = useState(false);
	const [selectedValue, setSelectedValue] = useState('');

	const [isLoading, setIsLoading] = useState(false);
	const [asyncData, setAsyncData] = useState([]);

	const onAsyncSearch = async (query) => {
		if (!query) {
			setAsyncData([]);
			return;
		} else {
			setIsLoading(true);
			// https://dev.svsf-ta.se/api/data/foreningar_active/?_filter=name LIKE 'a%'
			fetch_get("data/foreningar_active/?search=" + encodeURI(query))
				.then(data => {
					console.log('data', data);
					//
					setAsyncData(data.data);
					setIsLoading(false);
				})
				.catch(error => {
					console.error('Error fetching data:', error);
					setIsLoading(false);
				});
		}
		// setIsLoading(true);

		// const response = await fetch(url);
		// const data = await response.json();

		// setIsLoading(false);

		// setAsyncData(data.results);
	};

	const toggleModal = () => setModalOpen(!modalOpen);

	const submit = async (e) => {

		e.preventDefault();
		if (e.target.checkValidity()) {
			const response = await fetch_post("auth/reset-password", { "username": input });
			if (!response.ok) {
				setAlertColor('danger')
				setAlertMsg("Något gick fel, kontakta administratör.")
				setOpenAlert(true)
			}
			if (response.status == "error") {
				setAlertColor('danger')
				setAlertMsg(response.data.error_msg)
				setOpenAlert(true)
			} else {
				setAlertColor('primary')
				setAlertMsg(response.data.msg)
				setOpenAlert(true)
			}

		}
	}

	return (
		<>
			<MDBContainer className="py-2 h-100 mt-4">
				<MDBRow>
					<MDBCol md="3" />
					<MDBCol md="6">
						<BorderCol headline="Återställ lösenord" className='col-md-12 col-sm-12 square border border-primary rounded-6 p-4 mx-1 position-relative'>
							<MDBValidation onSubmit={submit} className='row g-3'>
								<MDBValidationItem feedback='Personnummer, IdrottID (IdrottOnline ID) eller E-post.' invalid>
									<MDBInput
										onChange={(e) => setInput(e.target.value)}
										label='Personnummer, IdrottID (IdrottOnline ID) eller E-post'
										required
									/>
								</MDBValidationItem>
								<MDBAlert open={openAlert} className='mt-3 w-100' color={alertColor}>
									{alertMsg}
								</MDBAlert>
								<div className='col-12 mt-3 text-center'>
									<MDBBtn type='submit'>Återställ lösenord</MDBBtn>

								</div>
							</MDBValidation>
						</BorderCol>
					</MDBCol>
					<MDBCol md="3" />
				</MDBRow>
				<MDBRow>
					<MDBCol md="3" />
					<MDBCol className="pt-4" md="6">
						<BorderCol headline="Kontrollera förening" className='col-md-12 col-sm-12 square border border-primary rounded-6 p-4 mx-1 position-relative'>
							<p className='text-center'>Kontrollera att föreningen är aktiverad i IdrottOnline mot SkytteTa</p>
							<div className='col-12 mt-3 text-center'>
								<MDBBtn color="info" onClick={toggleModal}>Kontrollera förening</MDBBtn>
							</div>
						</BorderCol>
					</MDBCol>
					<MDBCol md="3" />
				</MDBRow>

			</MDBContainer>

			<MDBModal open={modalOpen} setShow={setModalOpen} tabIndex='-1'>
				<MDBModalDialog>
					<MDBModalContent>
						<MDBModalHeader>
							<MDBModalTitle>Kontrollera förening</MDBModalTitle>
							<MDBBtn className='btn-close' color='none' onClick={toggleModal}></MDBBtn>
						</MDBModalHeader>
						<MDBModalBody>
							<MDBAutocomplete
								data={asyncData}
								isLoading={isLoading}
								label='Skriv föreningens namn...'
								noResults='Förening hittades inte'
								displayValue={(row) => row.name}
								onSearch={onAsyncSearch}
							/>
						</MDBModalBody>
						<MDBModalFooter>
							<MDBBtn color='secondary' onClick={toggleModal}>Stäng</MDBBtn>
						</MDBModalFooter>
					</MDBModalContent>
				</MDBModalDialog>
			</MDBModal>
		</>
	);
}