import React, { useState } from 'react';
import { fetch_pay, new_order, update_order } from '../payment/utils/PaymentRequest';
import { CreatePaymentBody } from '../payment/utils/CreatePaymentBody';
import { useLocation,useNavigate } from "react-router-dom";
import {    
  MDBRow, 
  MDBCol, 
  MDBCard,
  MDBCardBody,
  MDBCardTitle,
  MDBBtn, 
  MDBContainer} from 'mdb-react-ui-kit';

const LicenseType = ({data}) => {
	
  if(data.license.id == 8){
    return <MDBCardBody>
						<h5>{data.name}</h5>
            <MDBRow>
              <MDBCol md='6' className="text-start">Typ av licens</MDBCol>
              <MDBCol md='6' className="text-end"><p className="text-muted mb-0">{data.license.text}</p></MDBCol>
            </MDBRow>
            <MDBRow>
              <MDBCol md='6' className="text-start">Gren/diciplin</MDBCol>
              <MDBCol md='6' className="text-end"><p className="text-muted mb-0">{data.sport.text}</p></MDBCol>
            </MDBRow>
            <MDBRow>
              <MDBCol md='6' className="text-start">Åldersklass</MDBCol>
              <MDBCol md='6' className="text-end"><p className="text-muted mb-0">{data.age}</p></MDBCol>
            </MDBRow>
            <MDBRow>
              <MDBCol md='6' className="text-start">Giltlighetsperiod</MDBCol>
              <MDBCol md='6' className="text-end"><p className="text-muted mb-0">{data.startDate} - {data.endDate}</p></MDBCol>
            </MDBRow>
            <MDBRow>
              <MDBCol md='6' className="text-start">Tävling</MDBCol>
              <MDBCol md='6' className="text-end"><p className="text-muted mb-0">{data.event.text}</p></MDBCol>
            </MDBRow>
            <MDBRow>
              <MDBCol md='6' className="text-start">Pris</MDBCol>
              <MDBCol md='6' className="text-end"><p className="text-muted mb-0">{data.price}kr</p></MDBCol>
            </MDBRow>
          </MDBCardBody>
  }
  else{
    return <MDBCardBody>
						<h5>{data.name}</h5>
            <MDBRow>
              <MDBCol md='6' className="text-start">Typ av licens</MDBCol>
              <MDBCol md='6' className="text-end"><p className="text-muted mb-0">{data.license.text}</p></MDBCol>
            </MDBRow>
            <MDBRow>
              <MDBCol md='6' className="text-start">Gren/diciplin</MDBCol>
              <MDBCol md='6' className="text-end"><p className="text-muted mb-0">{data.sport.text}</p></MDBCol>
            </MDBRow>
            <MDBRow>
              <MDBCol md='6' className="text-start">Åldersklass</MDBCol>
              <MDBCol md='6' className="text-end"><p className="text-muted mb-0">{data.age}</p></MDBCol>
            </MDBRow>
            <MDBRow>
              <MDBCol md='6' className="text-start">Giltlighetsperiod</MDBCol>
              <MDBCol md='6' className="text-end"><p className="text-muted mb-0">{data.startDate} - {data.endDate}</p></MDBCol>
            </MDBRow>
            <MDBRow>
              <MDBCol md='6' className="text-start">Pris</MDBCol>
              <MDBCol md='6' className="text-end"><p className="text-muted mb-0">{data.price}kr</p></MDBCol>
            </MDBRow>
          </MDBCardBody>
          }
    
}

export default function ShopSummary() {

  const location = useLocation();
  const data = location.state;
	const navigate = useNavigate();

  async function handleClickPay() {      
    const order = await new_order();
    var price = 0;
    const payment = {
      description: "Licensköp",
      price: price,
      orders: data.map(user => {
        price = price + user.price;
        return {        
          reference: "O" + order.id + "U" + user.userID,
          name: "License_" + user.license.id,
          type: "PRODUCT",
          class: "License",
          description: user.license.text,
          vatPercent: 2500,
          amount: user.price,
          vatAmount: user.price*0.25
        }
      }),
      metadata: {
        data: data
      },  
      
    };
    localStorage.setItem("order-key", order.msg.key_id);
    const paymentBody = CreatePaymentBody(payment,order.id);
    const obj = await fetch_pay(paymentBody);
    await update_order(order.msg.key_id,obj,paymentBody);
    const url = obj.data.operations.find(({rel}) => rel === "redirect-checkout").href;
    window.open(url, "_self");
  }

	function totPrice(){
		let totPrice = 0
		data.map(user => (
			totPrice = totPrice + user.price
		))
		return totPrice
	}

   return (
    <MDBContainer className="py-4">
      <MDBRow className='d-flex justify-content-center'>
        <MDBCol size='4'>
          <MDBCard className="text-center rounded-4">
						<MDBRow className='g-0'>
								<MDBCardBody>
									<MDBCardTitle>Sammanfattning</MDBCardTitle>
								</MDBCardBody>
						</MDBRow>
						{data.map(user => (
							<span key={user.userID} className="square border">
							<LicenseType data={user}/>
							</span>
						))}
						<MDBCardBody>
							<MDBRow className='g-0'>
								<MDBCol md='6' className="text-start">Totalt: </MDBCol>
								<MDBCol md='6' className="text-end"><p className="text-muted mb-0">{totPrice()}kr</p></MDBCol>
							</MDBRow>
						</MDBCardBody>						
					</MDBCard>
					<MDBRow>
						<MDBCol md="12">
							<MDBBtn
									onClick={() => navigate(-1)}
									color='secondary'
									type="button"
									className="btn-rounded m-2"
									>
								Tillbaka
							</MDBBtn>
							<MDBBtn
									onClick={() => handleClickPay()}
									
									type="button"
									className="btn-rounded m-2 float-end"
									>
								Betala
							</MDBBtn>
						</MDBCol>
						{/* <MDBCol md="6">
							<MDBBtn
									onClick={() => handleClickPay()}
									type="button"
									className="btn btn-primary btn-rounded w-100"
									>
								Lägg till
							</MDBBtn>
						</MDBCol> */}
					</MDBRow>
					
        </MDBCol>
      </MDBRow>  
      </MDBContainer>
   );
}