export async function RemoveRole(key_id, role_id, obj_id){

	const response = await fetch("https://dev.svsf-ta.se/api/app/user/do_submit/" + key_id, {
		//headers: headers,
		method: 'POST',
		body: JSON.stringify({
			"action": "remove_admin",
			"role_id":role_id,
			"object_id":obj_id,
		})
			
	
		});
		
		if (!response.ok) {
			//throw new Error(
			 // `This is an HTTP error: The status is ${response.status}`
			//);
		}    
		const obj = await response.json();
		return  obj;

}