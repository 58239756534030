import { fetch_post } from "../../../../services/common";

export async function AddSportgren(parent_id, name ,type){
	
	const response = await fetch_post("app/sportgren/do_submit/" + parent_id, {
    action: "add_sportgren",
		db_type: type,
    db_name: name,
	})
	 
	return  response;
}