import { useNavigate, useLoaderData } from "react-router-dom";
import React,{ useState, useMemo, useEffect } from 'react';
import {    
  MDBBtn,
  MDBTable, 
  MDBTableHead, 
  MDBTableBody,
  MDBBadge,
	MDBSwitch,
	MDBCol,
	MDBRow,
	MDBContainer,
	MDBAlert
 } from 'mdb-react-ui-kit';
 import {MRT_Localization_SV} from 'material-react-table/locales/sv'
 import {
	 MaterialReactTable,
	 useMaterialReactTable,
 } from 'material-react-table';
import { fetch_get } from "../../../services/common";
import jwt_decode from "jwt-decode";
import { BorderCol } from "../../modules/BorderCol";
import AddDotAmount from "../payment/utils/AddDotAmount";

export async function loader({ params }) {
	const data = localStorage.getItem("token");
	var decoded = "";
	if (data) {
		decoded = jwt_decode(data);
	}

	const obj = {
		user: await fetch_get("app/user/all/" + params.key_id),
		guest: decoded.kid == params.key_id ? false : true
	}
		return obj;
}

export default function UserLicense(){

	const loadObject = useLoaderData();
	const user = loadObject.user.data;
	const [loading, setLoading] = useState(true)
	const [tableData, setTableData] = useState([]); 

	useEffect(() => {
  	
		setLoading(true)
		fetch_get("app/user/orders/" + user.key_id)
		.then((data) =>
			setTimeout(() => {
				setTableData(data.data);
				setLoading(false)
			}, 3000)
		);

  }, []);

	function padDigits(num) {
		return num.toString().padStart(2, '0');
	}
	
	function newDate(date) {
		
		return [
			date.getFullYear(),
			padDigits(date.getMonth() + 1),
			padDigits(date.getDate()),
		].join('-');
	}

	const tableColumns = useMemo(
    () => [
			{
        accessorKey: 'add_date',
        header: 'Betalningsdatum',
        size: 100,
				Cell: ({ cell }) => (
					<>{newDate(new Date(cell.getValue()))}</>
						
				)
      },
			{
        accessorKey: 'description',
        header: 'Produkt',
        size: 100,
      },
      {
        accessorKey: 'order_ref',
        header: 'Ordernummer',
        size: 50,
      },
			{
        accessorKey: 'reciever_name', 
        header: 'Betalare',
        size: 100,
      },
			{
        accessorKey: 'payee_name', 
        header: 'Betalningsmottagare',
        size: 50,
      },
			{
        accessorKey: 'payment_method', 
        header: 'Betalningsmetod',
        size: 50,
      },
			{
        accessorKey: 'amount',
        header: 'Belopp',
        size: 50,
				Cell: ({ cell }) => (
					<>{AddDotAmount(cell.getValue())}kr</>
						
				)
      },
			
    ],
    [],
  );
	
	const table = useMaterialReactTable({
    columns: tableColumns,
    data: tableData,
		state: {
			isLoading: loading
		},
    enableColumnFilters: false,
		enableColumnActions: false,
		enableDensityToggle: false,
		enableFullScreenToggle: false,
		enableHiding: false,
		enableFilters: false,
		localization:MRT_Localization_SV,
  });
  
    return (
			<>
				<MDBContainer className="py-4">
					<h3>Mina köp</h3>
					<hr/>
					<MDBRow className='d-flex justify-content-center'>
						<MDBCol md="12" className="mb-3">
							<BorderCol headline="Mina köp" className='col-md-12 col-sm-12 square border border-primary rounded-6 p-4 mx-1 position-relative'>
								<MaterialReactTable table={table} />
							</BorderCol>
						</MDBCol>
					</MDBRow>
				</MDBContainer>
			</>
		);

 
}