import React, { useState, useEffect, Fragment } from "react";
import { useMDBCookies } from 'mdb-react-cookies-management';
import { fetch_get, fetch_post } from "../../services/common";
import { detectIncognito } from "detectincognitojs";
import {
  MDBContainer,
  MDBNavbar,
  MDBNavbarBrand,
  MDBNavbarToggler,
  MDBIcon,
  MDBNavbarNav,
  MDBNavbarItem,
  MDBNavbarLink,
  MDBBtn,
  MDBDropdown,
  MDBDropdownToggle,
  MDBDropdownMenu,
  MDBDropdownItem,
  MDBCollapse,
  MDBSwitch ,
  MDBCheckbox


} from "mdb-react-ui-kit";

import {
  Outlet,
  NavLink,
  Link,
  useLoaderData,
  Form,
  redirect,
  //useNavigation,
  //useSubmit,
} from "react-router-dom";

import Icon_Svsf from "../svg/svg_icon_svsf";
import AuthService from "../../services/auth.service";


function handleLogout() {

  console.log('You clicked submit.');
  AuthService.logout().then((data) => { console.log('utloggad!'); window.location.replace("/"); });
}


export default function Navbar({ updateSidenav, sidenavState, showOnLoggedin, hideOnLoggedin }) {
  const [showBasic, setShowBasic] = useState(false);
  const [showOpen, setShowOpen] = useState(false);
  const [showUser, setShowUser] = useState("");
	const [associations, setAssociations] = useState([])
  const [navcolor, setNavColor] = useState("primary");

  const [navData_top, setData_top] = useState([]);
	const [navData_top_foreningar, setData_top_foreningar] = useState([]);
  const [loading, setLoading] = useState(true);
  const [newversion, setNewVesion] = useState(false);
  const [show_version, { setItem, removeItem }] = useMDBCookies('show_next_version', '');
  //const inc_result = detectIncognito();

  useEffect(() => {
    fetch_get('data/navbar_top')
      .then(data => {
        setData_top(data.data);
        // console.log('data.data', data.data);
        setLoading(false);
      })
      .catch(error => {
        console.error('Error fetching data:', error);
        setLoading(false);
      });

      if (sessionStorage.getItem("token")) {
        detectIncognito().then(data => {
          if (data.isPrivate) {
            setNavColor('danger');
          }
        });
        
      }

    AuthService.getCurrentUserName().then((data) => { setShowUser(data) });
		const user_key_id = localStorage.getItem("key_id");

		fetch_get('app/user/foreningar-admin/' + user_key_id)
      .then(data => {
        setAssociations(data.data);
        // console.log('data.data', data.data);
        setLoading(false);
      })
      .catch(error => {
        console.error('Error fetching data:', error);
        setLoading(false);
      });

  }, []);


  const list_top = (data) => {
    return (
      data.map(item => {
        return (
          <React.Fragment key={item.id}>
            {item.type == 'item_link' &&
              <MDBNavbarItem
                onClick={() => {
                  if (showOpen) {
                    setShowOpen(false);
                    setShowBasic(false);
                  }
                }}
              >
                <NavLink className={'text-light ' + item.classname} as={Link} to={item.to}><MDBIcon fas icon={item.icon} className={item.icon_classname} />{item.label}</NavLink>
              </MDBNavbarItem>
            }
            {item.type == 'divider' &&
              <MDBDropdownItem divider />
            }
            {item.type == 'dropdown' &&
              <MDBNavbarItem >
                <MDBDropdown>
                  <MDBDropdownToggle tag='a' className='nav-link text-light' role='button'>
                    <MDBIcon fas icon={item.icon} className={item.icon_classname} />{item.label}
                  </MDBDropdownToggle>
                  <MDBDropdownMenu>
                    {item.child && item.child.length &&
                      <>
                        {
                          item.child.map(ch =>{
														//specialfall för förening med submenu
														if(ch.id == 8 && associations.length > 1){
															return <MDBDropdownItem key={ch.id}><MDBDropdownItem className={ch.classname + " text-black"}>
																				<MDBIcon fas icon={ch.icon} className={ch.icon_classname} />{ch.label} &raquo;
																				</MDBDropdownItem>
																				<ul className="dropdown-menu dropdown-submenu">
																					{associations.map(association => {
																						return <MDBDropdownItem key={association.forening_id}>
																										<NavLink
																											className={ch.classname + " text-black"}
																											as={Link}
																											to={ch.to + "/" + association.forening_id + "/members"}
																										>
																											<MDBDropdownItem
																												onClick={() => {
																													if (showOpen) {
																														setShowOpen(false);
																														setShowBasic(false);
																													}
																												}}
																											>{association.forening_name}</MDBDropdownItem>
																										</NavLink>	
																									</MDBDropdownItem>
																					})}
																				</ul>
																			</MDBDropdownItem>
														} else {
															return <React.Fragment key={ch.id}>
																			{ch.type == 'divider' &&
																				<MDBDropdownItem divider />
																			}
																			{ch.type == 'link' &&
																				<NavLink
																					className={ch.classname}
																					as={Link}
																					to={ch.to}

																				>
																					<MDBDropdownItem
																						onClick={() => {
																							if (showOpen) {
																								setShowOpen(false);
																								setShowBasic(false);
																							}
																						}}
																					><MDBIcon fas icon={ch.icon} className={ch.icon_classname} />{ch.label}</MDBDropdownItem>
																				</NavLink>
																			}
																			{ch.type == 'link_loggout' &&
																				<MDBDropdownItem link onClick={handleLogout}><MDBIcon fas icon={ch.icon} className='pe-2' />{ch.label}</MDBDropdownItem>
																			}
																		</React.Fragment>
														}
                            }
                          )
                        }
                      </>
                    }
                  </MDBDropdownMenu>
                </MDBDropdown>
              </MDBNavbarItem>
            }
          </React.Fragment>
        )
      })
    )
  }


  const onChange_checkbox = async (e) => {
    if (e.target.checked) {
      setItem('v2');
     } else {
      removeItem();
     }
  };

  return (

    <header>
      <MDBNavbar expand='lg' className={"bg-" + navcolor }>
        <MDBContainer>
          {/* <MDBNavbarBrand href='#'><Icon_Svsf className="site-header__logo-img big-logo" />Skytte-Ta</MDBNavbarBrand> */}
          <Link className="navbar-brand text-light" as={Link} to="/"><Icon_Svsf className="site-header__logo-img big-logo " /><h2>Skytte TA</h2></Link>
          <MDBNavbarToggler
            aria-controls='navbarSupportedContent'
            aria-expanded='false'
            aria-label='Toggle navigation'
            onClick={() => {
              setShowBasic(!showBasic);
            }}
          >
            <MDBIcon icon='bars' fas />
          </MDBNavbarToggler>

          <MDBCollapse navbar
            open={showBasic}
						className='justify-content-end'
            onOpen={() => {
              setShowOpen(true);
            }}>
            <MDBNavbarNav right fullWidth={false} className='mb-2 mb-lg-0'>
              {list_top(navData_top)}
              <MDBNavbarItem className='nav-link'
                onClick={() => {
                  if (showOpen) {
                    setShowOpen(false);
                    setShowBasic(false);
                  }
                }}
              >
                {/* <MDBSwitch id='flexSwitchCheckDefault'   inline 
                label={show_version} 
                checked={show_version}
                 wrapperTag="span" 
                 wrapperClass="bg-secondary rounded-4" 
   
                  onChange={onChange_checkbox}/> */}
                              </MDBNavbarItem>              
            </MDBNavbarNav>


          </MDBCollapse>
        </MDBContainer>
      </MDBNavbar>

    </header>


  );
}
