import { newDate } from "../../license/utils/GetLicenseDates"

export async function AddSkyttekortMulti(user_arr,sport_id){

	const arr = []

	user_arr.map(user => {
		arr.push({	"db_user_id" : user.id,
								"db_skyttekort_nummer": user.licens_nr,
								"db_sportgren_id": sport_id,
								"db_skyttekort_datum": newDate(new Date())
							}) 
	})

	const jsonObj = {
		action: "add_skyttekort_multi",
		user_arr: arr,
	}
	
	const response = await fetch("https://dev.svsf-ta.se/api/app/user/do_submit/", {
	//headers: headers,
	method: 'POST',
	body: JSON.stringify(jsonObj)});
	
	if (!response.ok) {
		//throw new Error(
		 // `This is an HTTP error: The status is ${response.status}`
		//);
	}    
	const obj = await response.json();
	return  obj;
}