import { fetch_post } from "../../../../services/common";

export async function EditSportclass(sportclass_id, form){
	
	const response = await fetch_post("app/sportclass/do_submit/" + sportclass_id, {
    action: "edit_sportclass",
		db_def_klass_typer_id: form.def_klass_typer_id,
    db_name: form.name,
		db_age_min: form.age_min,
		db_age_max: form.age_max,
		db_gender: form.gender,
		db_results_min: form.results_min,
		db_results_max: form.results_max,
		db_results_to_count: form.results_to_count,
		db_sort_order: form.sort_order
	})
		
	return  response;
}