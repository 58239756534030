import { fetch_post } from "../../../../services/common";
import CompareDates from "../utils/CompareDates";

export async function AddLicense(user_key, startDate, endDate, sportgren_id){

	const response = await fetch_post("app/user/do_submit/" + user_key, {
    action: "add_licens",
		db_licens_id: 4,
    db_start_date: startDate,
		db_end_date: endDate,
		db_expired_date: endDate,
		db_status: CompareDates(startDate, endDate),
		db_sportgren_id: sportgren_id
	});
	
	if (!response.ok) {
		//throw new Error(
		 // `This is an HTTP error: The status is ${response.status}`
		//);
	}    
	const obj = await response.json();
	return  obj;
}