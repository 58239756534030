import { useLoaderData,useNavigate,useLocation } from "react-router-dom";
import React, { useState, useRef } from 'react';
import { fetch_json } from "../../../services/common";
import { 
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBStepper,
  MDBStepperStep,
  MDBIcon,
  MDBAlert,
  MDBDatepicker,
  MDBValidation,
  MDBValidationItem,
  MDBSelect,
  MDBBtn,
  MDBCollapse,
  MDBListGroup,
  MDBListGroupItem
  } from "mdb-react-ui-kit";
import NotFound404 from "../NotFound404";
import GetActiveLicense from "./utils/GetActiveLicense";
import { BorderCol } from "../../modules/BorderCol";
import { useMDBCookies } from 'mdb-react-cookies-management';

export async function loader({ params }) {
  const obj = []
  obj[0] = await fetch_json("app/list/lov-grenar" , 'GET');
  obj[1] = await fetch_json("app/list/do_not_use?_select=id, name&_where=event_type_id=999&_table=events" , 'GET');
  obj[2] = await fetch_json("app/list/licenser" , 'GET');
  
  return obj;
}

export default function LicenseStepper() {

  const location = useLocation();
  const users = location.state;
  if(!users){
    return <NotFound404/>
  }
  const usersLength = users.length;
  const prevRef = useRef(null);
  const nextRef = useRef(null);
  const sportLoader = useLoaderData()[0];
  const eventsLoader = useLoaderData()[1];
  const licensesLoader = useLoaderData()[2];
  const [user,setUser] = useState(users[0]);
  const eventArray = (name, id, formName) => { return { text: name, value: id, name: formName}};
  const eventRows = [];
  const sportArray = (name, id, formName) => { return { text: name, value: id, name: formName}};
  const sportRows = [];
  const licenseArray = (name, id, formName, minAge, maxAge, hidden) => { return { text: name, value: id, name: formName, min_age: minAge, max_age: maxAge, hidden: hidden}};
  const licenseRows = [];
	const [formCookie, { setItem, removeItem }] = useMDBCookies('license-form', '');
  const initialFormValues = users.map(user => {
		if(formCookie && JSON.parse(formCookie).find(obj => obj.userID == user.id)){
			return JSON.parse(formCookie).find(obj => obj.userID == user.id)
		} else {
			return  {license: null,
				additionalLicense: "-",
				sport: null,
				event: null,
				startDate: "",
				endDate: "",
				price: 0,
				age: (new Date().getFullYear() - new Date(user.birth_date).getFullYear() > 20) ? "Senior" : "Junior",
				userID: user.id,
				name: user.first_name + " " + user.last_name,
				user_key: user.key_id}
		}

  })
	
  const [formValues, setFormValues] = useState(initialFormValues);
  const navigate = useNavigate();
  const [showOptionalInputs, setShowOptionalInputs] = useState(false);
  const [disableStartDate, setDisableStartDate] = useState(true)
  const [disableEndDate, setDisableEndDate] = useState(true)
  const [minDate, setMinDate] = useState(null);
  const dayInMS = 86400000;  
  const pricePerDay = Number(licensesLoader.data.find(obj => obj.id == 8).amount);
  const userAge = new Date().getFullYear() - new Date(user.birth_date).getFullYear();
  const currentDate = newDate(new Date(new Date().getFullYear(),new Date().getMonth(),new Date().getDate()));
  const [showAlert,setShowAlert] = useState(GetActiveLicense(user.licenser))
  const [selectedIndex,setSelectedIndex] = useState(1);

  sportLoader.data.map(sport => {
    const selectSport = sportArray(sport.text, Number(sport.value), "sport");
    sportRows.push(selectSport);
  },);

  eventsLoader.data.map(event => {
    const selectEvent = eventArray(event.name, Number(event.id), "event");
    eventRows.push(selectEvent);
  },);

  licensesLoader.data.map(license => {
    const selectLicense = licenseArray(license.name, Number(license.id) ,"license", license.min_age, license.max_age, true);
    licenseRows.push(selectLicense);
  });

  const onStepperChange = (i) => {

    setUser(users[i-1])
    setSelectedIndex(i);
    const userLicenses = user.licenser ? user.licenser : [];
    setShowAlert(false)
    if(userLicenses.length > 0){
      userLicenses.map(element => {
        if(element.status === "ACTIVE"){
          setShowAlert(true)
        }
      });
    }
    if(formValues.find(obj => obj.userID == user.id).license){
      const licenseID = formValues.find(obj => obj.userID == user.id).license.id
      if(licenseID === 8){
        setShowOptionalInputs(true)
        setDisableStartDate(false)
        setDisableEndDate(false)
      } else {
        setShowOptionalInputs(false);
        setDisableEndDate(true);
        const fixedStartDate = licensesLoader.data.find(obj => obj.id == licenseID).fixed_start_date;
        if(!fixedStartDate){
          setDisableStartDate(false);
        } else {
          setDisableStartDate(true);
        }
      }
    } else {
      setShowOptionalInputs(false)
      setDisableStartDate(true)
      setDisableEndDate(true)
    }
    
  }
  
  const getLatestStartDate = () => {
    var latestDate = currentDate
    const userLicenses = user.licenser ? user.licenser : [];
    if(userLicenses.length > 0){
      userLicenses.map(element => {
        if(element.status === "ACTIVE"){
          if(formatDate(latestDate).getTime() < formatDate(element.end_date).getTime()){
            latestDate = element.end_date;
          }
        }
      });
    }
    var date = new Date(latestDate);
    if(latestDate != currentDate){
      date.setDate(date.getDate() + 1);
    }
    return newDate(date)
  }

  function padDigits(num) {
    return num.toString().padStart(2, '0');
  }
  
  function newDate(date) {
    return [
      date.getFullYear(),
      padDigits(date.getMonth() + 1),
      padDigits(date.getDate()),
    ].join('-');
  }

  function adjustDate(licenseID,fromDate){
    var date = new Date(fromDate);
    licensesLoader.data.map(license => {
      if((license.id == licenseID) && (license.rolling_periods != null)){
        date.setMonth(date.getMonth()+Number(license.rolling_periods));
      }
      if((license.id == licenseID) && license.fixed_end_date != null){
        date = new Date(license.fixed_end_date)
      }
    })
    return newDate(date);
  }

  function getLicensePeriod(licenseID){
    return licensesLoader.data.find(obj => obj.id == licenseID ).id;
  }

  function getLicenseAmount(licenseID){
    return licensesLoader.data.find(obj => obj.id == licenseID ).amount;
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    if(e.target.checkValidity()){
			setItem(formValues, { expires: 1 })
      navigate("/license-summary",{state: formValues});
    }
  };

  function calculateDays(startDate,endDate){
    if(startDate && endDate){
      if(startDate !== "" && startDate !== ""){
        return ((formatDate(endDate).getTime()+dayInMS)-(formatDate(startDate).getTime()))/dayInMS;
      }
      else return 0
    }
    else return 0
  }

  function formatDate(date){
    if(date != null){return new Date(date.split("/").reverse().join("-"));}
  }

  const onStartDateChange = (e) => {
    if(formValues.find(obj => obj.userID == user.id).license){
      if(formValues.find(obj => obj.userID == user.id).license.id == 8){
        if(e !== ""){
          const date = formatDate(e);
          date.setDate(date.getDate() - 1);
          setMinDate(new Date(date));
          if(!formValues.find(obj => obj.userID == user.id).endDate){
            setFormValues(formValues.map(form => {                
              if(form.userID === user.id){
                return { ...form, 
                  ["startDate"]:e,
                  ["endDate"]: e,
                  ["price"]:pricePerDay*calculateDays(e,e) };
              } else {
                return form;
              }
            })); 
          }
          if(formValues.find(obj => obj.userID == user.id).endDate){
            if(formatDate(e).getTime() > formatDate(formValues.find(obj => obj.userID == user.id).endDate).getTime()){
              setFormValues(formValues.map(form => {
                if(form.userID === user.id){
                  return ({ ...form, 
                    ["startDate"]:e, 
                    ["endDate"]: e,
                    ["price"]:pricePerDay*calculateDays(e,e) });
                } else {
                  return form;
                }
              }));
            }
            else {
              setFormValues(formValues.map(form => {
                if(form.userID === user.id){
                  return ({ ...form, 
                    ["price"]:pricePerDay*calculateDays(e,formValues.find(obj => obj.userID == user.id).endDate) });
                } else {
                  return form;
                }
              }));
            };
          }
        } else {
          setFormValues(formValues.map(form => {
            if(form.userID === user.id){
              return { ...form, 
                ["startDate"]:getLatestStartDate() };
            } else {
              return form;
            }
          }));
        }
      } else {
        const newEndDate = adjustDate(getLicensePeriod(formValues.find(obj => obj.userID == user.id).license.id),e);
        setFormValues(formValues.map(form => {
          if(form.userID === user.id){
            return { ...form, 
              ["startDate"]: e ,["endDate"]: newEndDate };
          } else {
            return form;
          }
        }));
      } 
    }
  };

  const onEndDateChange = (e) => {
    if(formValues.find(obj => obj.userID == user.id).license){
      if(formValues.find(obj => obj.userID == user.id).license.id == 8){
        if(e !== ""){
          setFormValues(formValues.map(form => {
            if(form.userID === user.id){
              return { ...form, 
                ["endDate"]:e,
                ["price"]: pricePerDay*calculateDays(formValues.find(obj => obj.userID == user.id).startDate,e)};
            } else {
              return form;
            }
          }));
        }
      }
    }
  };

  const onChange = (e) => {
    if(e.name === "license"){
      if(e.value == 8){
        setDisableEndDate(false);
        setDisableStartDate(false);
        setShowOptionalInputs(true);
        setFormValues(formValues.map(form => {
          if(form.userID === user.id){
            return { ...form, 
              [e.name]: {id:e.value,text:e.text},
              ["startDate"]: getLatestStartDate(),
              ["endDate"]: getLatestStartDate(),
              ["price"]: 0};
          } else {
            return form;
          }
        }))
      }
      else{
        setShowOptionalInputs(false);
        setDisableEndDate(true);
        const fixedStartDate = licensesLoader.data.find(obj => obj.id == e.value).fixed_start_date;
        if(!fixedStartDate){
          setDisableStartDate(false);
          setFormValues(formValues.map(form => {
            if(form.userID === user.id){
              return { ...form, 
                [e.name]: {id:e.value,text:e.text},
                ["startDate"]: getLatestStartDate(),
                ["price"]: Number(getLicenseAmount(e.value)),
                ["endDate"]: adjustDate(e.value,getLatestStartDate())};
            } else {
              return form;
            }
          }))
        } else {
          setDisableStartDate(true);
          setFormValues(formValues.map(form => {
            if(form.userID === user.id){
              return { ...form, 
                [e.name]: {id:e.value,text:e.text},
                ["startDate"]: fixedStartDate,
                ["price"]: Number(getLicenseAmount(e.value)),
                ["endDate"]: adjustDate(e.value,fixedStartDate)};
            } else {
              return form;
            }
          }));
        }
      }
    } else {
      setFormValues(formValues.map(form => {
        if(form.userID === user.id){
          return { ...form, 
            [e.name]: {id:e.value,text:e.text}};
        } else {
          return form;
        }
      }));
    }
  };

  return (
    <>
      <MDBContainer className="py-4"> 
        {showAlert ? <MDBAlert 
          key={user.id}
          className='d-flex justify-content-between'
          defaultOpen
          autohide 
          delay={5000}
          color='warning'
          dismissBtn
          position='bottom-center'>
          <p className='mb-0'>
          Aktiv licens finns redan för {user.first_name + " " + user.last_name}.<br/>
          Startdatum justeras efter när licenserna går ut.
          </p>
        </MDBAlert> : null}
				
        <MDBRow>
          <MDBCol md='9'>
					<BorderCol headline="Köp licens" className='col-md-12 col-sm-12 square border border-primary rounded-6 p-4 mx-1 position-relative'>
            <MDBValidation onSubmit={handleSubmit} className='row g-3'>
              <MDBStepper linear type='vertical' externalNext={nextRef} externalPrev={prevRef} onChange={onStepperChange}>
                {users.map((user,index) => {
                  const userForm = formValues.find(obj => obj.userID == user.id);
                  var minStartDate = new Date(getLatestStartDate());
                  minStartDate.setDate(minStartDate.getDate() - 1);
                  return <MDBStepperStep headIcon={<MDBIcon fas icon='user' />} headText={user.first_name + " " + user.last_name} itemId={index+1} key={user.id}>                  
                    <MDBCol md='12'>
                      <MDBRow className='d-flex justify-content-center'>
                          <MDBRow className='mb-3'>
                              <MDBSelect
                                className='col-md-5'
                                data={licenseRows.map(license => {
                                  if(license.min_age <= userAge && license.max_age >= userAge){
                                    license.hidden = false;
                                  }
                                  return license
                                })}
                                label='Typ av licens'
                                name="license"                              
                                onValueChange={onChange}
                                preventFirstSelection
                                validation
                                validFeedback=''
                                invalidFeedback='Ange typ av licens'
																value={userForm.license ? userForm.license.id : null}
                              />
                              <MDBSelect
                                className='col-md-4'
                                label='Gren/diciplin'
                                data={sportRows}
                                name="sport"
                                validation
                                validFeedback=''
                                invalidFeedback='Ange en gren'
                                onValueChange={onChange}
                                preventFirstSelection
																value={userForm.sport ? userForm.sport.id : null}
                              />
                            <MDBValidationItem className='col-md-3'>
                              <MDBSelect
                                label="Åldersklass"
                                data={[
                                  { text: userForm.age }
                                ]}
                                disabled
                              />
                            </MDBValidationItem>
                          </MDBRow>
                          <MDBRow className='mb-3'>
                            <MDBValidationItem className='col-md-3'>
                              <MDBDatepicker 
                                disablePast 
                                clearBtnText='' 
                                format='yyyy-mm-dd' 
                                disabled 
                                inputToggle={disableStartDate} 
                                className='bg-white text-dark' 
                                onChange={onStartDateChange} 
                                value={userForm.startDate}
                                required
                                min={minStartDate} 
                                name="startDate" 
                                label='Startdatum'/>
                            </MDBValidationItem>
                            <MDBValidationItem className='col-md-3'>
                              <MDBDatepicker 
                                disablePast 
                                clearBtnText='' 
                                format='yyyy-mm-dd' 
                                disabled 
                                inputToggle={disableEndDate} 
                                className='bg-white text-dark'
                                onChange={onEndDateChange} 
                                min={minDate} 
                                value={userForm.endDate}
                                required
                                name="endDate" 
                                label='Slutdatum'/>
                            </MDBValidationItem>
                            <MDBValidationItem className={showOptionalInputs ? "d-block col-md-3" : "d-none"}>
                              <MDBSelect
                                label='Tävling'
                                data={eventRows}
                                name="event"
                                validation={(userForm.license && userForm.license.id == 8) ? true : false}
                                validFeedback=''
                                invalidFeedback='Ange en gren'
                                onValueChange={onChange}
                                preventFirstSelection
                              />
                            </MDBValidationItem>
                          </MDBRow>
                        
                      </MDBRow>
                    </MDBCol>
                  </MDBStepperStep >
                  
                })}
              </MDBStepper>
              <MDBRow>
								<MDBCol>
									<MDBBtn
										onClick={() => navigate(-1)}
										color='tertiary'
										type="button"
										>
									Avbryt
								</MDBBtn>
								</MDBCol>
                <MDBCol className="d-flex justify-content-end">
                  <MDBBtn ref={prevRef} type="button" color="secondary" className={selectedIndex > 1 ? "d-block mx-2" : "d-none"}>Föregående</MDBBtn>
                  <MDBBtn ref={nextRef} type={usersLength === selectedIndex ? "submit" : "button"}>{usersLength === selectedIndex ? "Slutför" : "Nästa"}</MDBBtn>
                </MDBCol>
              </MDBRow>
            </MDBValidation>
						</BorderCol>
          </MDBCol>
          <MDBCol md='3'>
            <MDBListGroup light>
              {formValues.map((form,index) => {
                return  <MDBListGroupItem tag='button' action noBorders type='button' className='px-3' key={form.userID}>
                          <h6 className='bg-light p-2 border-top border-bottom'>{form.name}</h6>
                          <MDBCollapse open={(selectedIndex === index + 1) ? true : false}>
                            <strong>Licens: </strong>{form.license == null ? "-" : form.license.text}<br/>
                            <strong>Gren/diciplin: </strong>{form.sport == null ? "-" : form.sport.text}<br/>
                            <strong>Åldersklass: </strong>{form.age == null ? "-" : form.age}<br/>
                            <strong>Startdatum: </strong>{form.startDate == "" ? "-" : form.startDate}<br/>
                            <strong>Slutdatum: </strong>{form.endDate == "" ? "-" : form.endDate}<br/>
                            <strong>Pris: </strong>{form.price}<br/>
                          </MDBCollapse >
                        </MDBListGroupItem>
              })}
            </MDBListGroup>
          </MDBCol>
        </MDBRow>
				
      </MDBContainer>
    </>
  );
}