import { useLoaderData,useNavigate } from "react-router-dom";
import { fetch_json, fetch_get } from "../../../services/common";
import React, { useState, useEffect } from "react";
import {
	MDBBtn,
	MDBAlert,
	MDBContainer,
	MDBRow,
	MDBCol,
	MDBSpinner,
	MDBSelect
} from "mdb-react-ui-kit";
import LicenseSelectDetails from "./LicenseSelectDetails";
import { BorderCol } from "../../modules/BorderCol";
import jwt_decode from "jwt-decode";
import { GetValidThru } from "./utils/GetLicenseDates";

export async function loader({ params }) {
    const data = localStorage.getItem("token");
    var decoded = "";
    if (data) {

      decoded = jwt_decode(data);
			console.log(decoded)
			const obj = {
				user: await fetch_get("app/user/all/" + decoded.kid)
			}
				return obj;
    } else {
			return null
		}

  }

export default function LicenseDisplayOptions(){

	const loadObject = useLoaderData();
	const user = loadObject.user.data;
	const userAge = new Date().getFullYear() - new Date(user.birth_date).getFullYear();
	const [loading, setLoading] = useState(false)
	const [licenseList, setLicenseList] = useState(null);
	const [sportList, setSportList] = useState([]);
	const [eventsList, setEventsList] = useState(null);
	const [showLicenseOptions, setShowLicenseOptions] = useState(false)
	const [selectedLicense, setSelectedLicense] = useState(null)
	const [skyttekortExists, setSkyttekortExists] = useState(false)
	const [selectValue, setSelectValue] = useState(0);
	const [selectedSport, setSelectedSport] = useState(null)
	let periodIndex = 0;

	useEffect(() => {
		setLoading(true)
		fetch_get("app/user/skyttekort/" + user.key_id).then((data) => {
			setTimeout(() => {
				if(data.data[0]){
					data.data.map(skyttekort => {
						if(skyttekort.status == 'ACTIVE'){
							setSkyttekortExists(true)
						}
					})
				} 
			},setLoading(false), 2000)
		})
	}, []);
	

	useEffect(() => {
		setLoading(true)
		fetch_get("app/list/licenser").then((data) => {
			setTimeout(() => {
				if(data.data[0]){
					const licenseArray = (name, id, formName, minAge, maxAge, type, amount, status,rolling_periods, fixed_start_date ,fixed_end_date ,hidden) => { return { text: name, id: id, name: formName, min_age: minAge, max_age: maxAge, type: type, amount: amount, status: status, rolling_periods: rolling_periods, fixed_start_date: fixed_start_date ,fixed_end_date: fixed_end_date ,hidden: hidden}};
  				const licenseRows = [];
					data.data.map(license => {
						const selectLicense = licenseArray(license.name, Number(license.id) ,"license", license.min_age, license.max_age, license.type, license.amount, license.status, license.rolling_periods, license.fixed_start_date ,license.fixed_end_date ,true);
						licenseRows.push(selectLicense);
					});
					const sortedLicenseRows = licenseRows.sort((a,b) => a.amount - b.amount)
					setLicenseList(sortedLicenseRows)
				} 
			}, setLoading(false), 2000)
		})
		fetch_get("app/list/lov-grenar").then((data) => {
			setTimeout(() => {
				if(data.data[0]){
					const sportArray = (name, id, formName) => { return { text: name, value: id, name: formName}};
  				const sportRows = [{ text: '', hidden: true }];
					data.data.map(sport => {
						const selectSport = sportArray(sport.text, Number(sport.value), "sport");
						sportRows.push(selectSport);
					},);
					setSportList(sportRows)
				} 
			}, setLoading(false), 2000)
		})
		fetch_get("app/list/do_not_use?_select=id, name&_where=event_type_id=999&_table=events").then((data) => {
			setTimeout(() => {
				if(data.data[0]){
					const eventArray = (name, id, formName) => { return { text: name, value: id, name: formName}};
					const eventRows = [];
					data.data.map(event => {
						const selectEvent = eventArray(event.name, Number(event.id), "event");
						eventRows.push(selectEvent);
					},);
					setEventsList(eventRows)
				} 
			}, setLoading(false), 2000)
		})
	}, []);

	const toggleLicenseOptionsModal = (license) => {
		setSelectedLicense(license)
		setShowLicenseOptions(!showLicenseOptions)
	};

	return (
		<>
			{loading ?     
				<MDBRow className="d-flex justify-content-center py-5">
					<MDBSpinner color='primary'>
						<span className='visually-hidden'>Loading...</span>
					</MDBSpinner>
				</MDBRow> :
				<MDBContainer className="py-4">
				<MDBAlert
					color='warning'
					autohide
					position='bottom-center'
					appendToBody
					open={!skyttekortExists}
				>
					Skyttekort saknas, ingen licens kan köpas utan skyttekort.
				</MDBAlert>
				<MDBRow className='d-flex justify-content-center'>
					<MDBCol md="4">
					<BorderCol headline="Nuvarande licens" className='col-md-12 col-sm-12 square border border-primary rounded-6 p-4 mx-1 my-4 position-relative'>
						<div>Giltlig till: <span className="text-muted">{user.licenser ? GetValidThru(user.licenser) : "Ingen aktiv licens"}</span></div>
					</BorderCol>
					</MDBCol>
				</MDBRow>

				{/* <BorderCol headline="Årslicens" className='col-md-12 col-sm-12 square border border-primary rounded-6 p-4 mx-1 my-4 position-relative'>
					{licenseList ? licenseList.map((license) => {			
						if(license.type == "FIXED" && license.status == "ACTIVE" && (license.min_age <= userAge && license.max_age >= userAge)){							
							return <React.Fragment key={license.id}>
											<MDBRow>
												<MDBCol md='6'>
													<h5>{license.text}</h5>
													<b>{license.amount}kr</b>
												</MDBCol>
												<MDBCol className="text-end" md='6'>
													<MDBBtn  onClick={() => toggleLicenseOptionsModal(license)}>Välj</MDBBtn>
												</MDBCol>
											</MDBRow>
										</React.Fragment>
						}
					}) : null}
				</BorderCol> */}
				<BorderCol headline="Rullande licens" className='col-md-12 col-sm-12 square border border-primary rounded-6 p-4 mx-1 my-4 position-relative'>
					{licenseList ? licenseList.map((license) => {			
						if(license.id === 4 && license.status == "ACTIVE" && (license.min_age <= userAge && license.max_age >= userAge)){							
							periodIndex++
							return <React.Fragment key={license.id}>
											{periodIndex > 1 ? <hr/> : null}
											<MDBRow between>
												<MDBCol md='6'>
													<h5>{license.text}</h5>
													<b>{license.amount}kr</b>
												</MDBCol>
												<MDBCol>
													<MDBRow end>
														<MDBCol md='5'>
															<MDBSelect 
																preventFirstSelection
																data={sportList}
																label='Välj grenkommitté'
																value={selectValue}
																onChange={(e) => {
																	setSelectValue(e.value);
																	setSelectedSport(e)
																}}
																/>
														</MDBCol>
														<MDBCol md='2'>
															<MDBBtn disabled={!skyttekortExists || selectValue === 0} onClick={() => toggleLicenseOptionsModal(license)}>Välj</MDBBtn>
														</MDBCol>
													</MDBRow>
												</MDBCol>
											</MDBRow>
										</React.Fragment>
						}
					}) : null}
				</BorderCol>
				{/* <BorderCol headline="Flexibel licens" className='col-md-12 col-sm-12 square border border-primary rounded-6 p-4 mx-1 position-relative'>
				{licenseList ? licenseList.map((license) => {			
						if(license.type == "ROLLING_FLEXIBEL"  && license.status == "ACTIVE" && (license.min_age <= userAge && license.max_age >= userAge)){							
							return <React.Fragment key={license.id}>
											<MDBRow>
												<MDBCol md='6'>
													<h5>{license.text}</h5>
													<b>{license.amount}kr/dag</b>
													<div className="text-muted">Välj antal dagar som licensen ska gälla i nästa steg.</div>
												</MDBCol>
												<MDBCol className="text-end" md='6'>
													<MDBBtn  onClick={() => toggleLicenseOptionsModal(license)}>Välj</MDBBtn>
												</MDBCol>
											</MDBRow>
										</React.Fragment>
						}
					}) : null}
				</BorderCol> */}
				
				{selectedLicense && showLicenseOptions ? <LicenseSelectDetails selectedLicense={selectedLicense} user={user} events={eventsList} sport={selectedSport} show={showLicenseOptions} close={() => setShowLicenseOptions(false)}/> : null}
			</MDBContainer>}
			
			
		</>
	);
}
	

