import React, { useState, useEffect } from "react";
import {
  MDBCard,
  MDBCardBody,
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBBtn,
  MDBIcon,
  MDBTypography,
  MDBTable,
  MDBTableHead,
  MDBTableBody,
	MDBSpinner
} from "mdb-react-ui-kit";
import { fetch_get } from "../../../services/common";
import Icon_Svsf from "../../svg/svg_icon_svsf";
import AddDotAmount from "./utils/AddDotAmount";

export default function PaymentSuccessful() {

	const [order, setOrder] = useState(null)
	const [loading, setLoading] = useState(null)
	const [metadata, setMetadata] = useState(null)
	const [orderItems,setOrderItems] = useState(null)

	useEffect(() => {
		const order_key = localStorage.getItem("order-key");
		setLoading(true)
		fetch_get("app/swedbankpay/order-summary/" + order_key)
		.then((data) =>
			setTimeout(() => {
				setOrder(data.data[0]);
				setOrderItems(data.data.order_items)
				setMetadata(JSON.parse(data.data[0].metadata).data[0])
				setLoading(false)
			}, 3000)
		);

  }, []);

	function padDigits(num) {
		return num.toString().padStart(2, '0');
	}
	
	function newDate(date) {
		
		return [
			date.getFullYear(),
			padDigits(date.getMonth() + 1),
			padDigits(date.getDate()),
		].join('-');
	}


  return (
    <MDBContainer className="py-5">
			{loading || !order || !metadata || !orderItems? 
			<div className='d-flex justify-content-center'>
				<MDBSpinner role='status'>
					<span className='visually-hidden'>Loading...</span>
				</MDBSpinner>
			</div> :
			<>
			<MDBRow className="text-center">
				<p>
					Mail med orderbekräftelse har skickats till {order.email ? order.email : "-mail saknas-"}
				</p>
			</MDBRow>
			<MDBCard className="p-4">
				<MDBCardBody>
					<MDBContainer>
						<MDBCol md="12" className="text-center">
							<h3>Orderbekräftelse</h3>
						</MDBCol>
					</MDBContainer>
					<MDBRow>
						<MDBCol xl="8">
              <MDBTypography listUnStyled>
								<li className="text-muted">Beställare:</li>
                <li className="text-muted">
                  <span style={{ color: "#5d9fc5" }}>{order.reciever_name + " (" + order.iid_nr + ")"} </span>
                </li>
                <li className="text-muted">{order.email ? order.email : "-"}</li>
                <li className="text-muted">
                  <MDBIcon fas icon="phone-alt" /> {order.phone ? order.phone : "-"}
                </li>
              </MDBTypography>
            </MDBCol>
						<MDBCol xl="4">
							<MDBTypography listUnStyled>
								<li className="text-muted">
									<span className="fw-bold ms-1">Typ: </span>{order.description}
								</li>
								<li className="text-muted">
									<span className="fw-bold ms-1">Referensnummer: </span>{order.order_ref}
								</li>
								<li className="text-muted">
									<span className="fw-bold ms-1">Orderdatum: </span>
									{newDate(new Date(order.add_date))}
								</li>
								<li className="text-muted">
									<span className="fw-bold ms-1">Status:</span>
									<span className="badge bg-primary fw-bold ms-1">
										Betald
									</span>
								</li>
							</MDBTypography>
						</MDBCol>
					</MDBRow>
					<MDBRow className="my-2 mx-1 justify-content-center">
						<MDBTable striped borderless>
							<MDBTableHead
								className="text-white"
								style={{ backgroundColor: "#0065A4" }}
							>
								<tr>
									<th scope="col">Produkt</th>
									<th scope="col">Beskrivning</th>
									<th scope="col">Avser användare</th>
									<th scope="col">Summa</th>
									<th scope="col">Moms</th>
								</tr>
							</MDBTableHead>
							<MDBTableBody>
								{orderItems.map(item => {
									return <tr>
													<td>{item.class ? item.class === "License" ? "Licens" : "-" : "-"}</td>
													<td>{item.description}</td>
													<td>{item.reciever_name + " (" + item.iid_nr + ")"}</td>
													<td>{AddDotAmount(item.amount)}kr</td>
													<td>{AddDotAmount(item.vat_amount)}</td>
												</tr>
								})}
							</MDBTableBody>
						</MDBTable>
					</MDBRow>
					<MDBRow>
						<MDBCol xl="8">
							<p className="ms-3">
								
							</p>
						</MDBCol>
						<MDBCol xl="3">
							<MDBTypography listUnStyled>
								<li className="text-muted ms-3 mt-2">
									<span className="text-black me-4">Moms(25%)</span>{AddDotAmount(order.vat_amount)}
								</li>
							</MDBTypography>
							<p className="text-black float-start">
								<span className="text-black me-3">Summa</span>
								<span style={{ fontSize: "25px" }}>{AddDotAmount(order.amount)}</span>
							</p>
						</MDBCol>
					</MDBRow>
					<hr />
					<MDBRow>
						<MDBCol xl="10">
							<p>Tack för ditt köp!</p>
						</MDBCol>
					</MDBRow>
				</MDBCardBody>
			</MDBCard>
			</>
			}
    </MDBContainer>
  );
}