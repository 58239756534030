import { fetch_json } from "../../../../services/common";


export async function EditSkyttekort(status,user_key,skyttekortID){

	const response = await fetch("https://dev.svsf-ta.se/api/app/user/do_submit/" + user_key, {
	//headers: headers,
	method: 'POST',
	body: JSON.stringify({
    "action": "remove_skyttekort",
    "id": skyttekortID,
    "db_status": status
})
		

	});
	
	if (!response.ok) {
		//throw new Error(
		 // `This is an HTTP error: The status is ${response.status}`
		//);
	}    
	const obj = await response.json();
	return  obj;
}