import React, { useState } from 'react';
import { useLoaderData } from 'react-router-dom';
import {    
  MDBRow, 
  MDBCol, 
  MDBContainer,
	MDBValidation,
	MDBValidationItem,
	MDBInput,
	MDBBtn,
	MDBAlert
 } from 'mdb-react-ui-kit';
import { BorderCol } from "../../modules/BorderCol";

export async function loader({ params }) {
		const obj = {
			key_id: params.key_id,
			key_link: params.key_link
		}
		return obj;
  }

export default function ResetPassword() {

	const loadObject = useLoaderData();
	const key_id = loadObject.key_id;
	const key_link = loadObject.key_link;
	const [pwFeedback, setPwFeedback] = useState("Ange ett lösenord");
	const [openError, setOpenError] = useState(false);
	const [alertMsg, setAlertMsg] = useState("Nya lösenorden matchar inte.")
	const [alertColor, setAlertColor] = useState('warning')
	const [pwChanged, setPwChanged] = useState(false)

	const [passworldFormValue, setPasswordFormValue] = useState({
		oldPassword: '',
		newPassword: '',
		newPassword2: ''
	})

	const onChange = (e) => {
		setOpenError(false)
    setPasswordFormValue({ ...passworldFormValue, [e.target.name]: e.target.value });
		if((passworldFormValue.newPassword.length == 0) && passworldFormValue.newPassword2.length == 0){
			setPwFeedback("Ange ett lösenord")
		}
		
  };

	const changePassword = async (e) => {
		e.preventDefault();

		//Minst 8 tecken, en stor och en liten och en siffra.
		const regexp = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d\w\W]{8,}$/
		
    if (e.target.checkValidity()) {
			if(passworldFormValue.newPassword !== passworldFormValue.newPassword2){
				setAlertColor('warning')
				setAlertMsg("Nya lösenorden matchar inte.")
				setOpenError(true)
			}
			if(!regexp.exec(passworldFormValue.newPassword)){
				setAlertColor('danger')
				setAlertMsg("Lösenordet måste vara minst 8 tecken långt och innehålla minst en liten och en stor bokstav och minst en siffra.")
				setOpenError(true)
			}
			if(regexp.exec(passworldFormValue.newPassword) && (passworldFormValue.newPassword == passworldFormValue.newPassword2)){
				const response = await fetch("https://dev.svsf-ta.se/api/auth/change-password", {
						//headers: headers,
						method: 'POST',
						body: JSON.stringify({
							"key_id": key_id,
							"reset_password_link" : key_link,
							"new_password": passworldFormValue.newPassword}
						)
	
					});
					if (!response.ok) {
						
					}    
					const obj = await response.json();
					if(obj.status != "error"){
						setPwChanged(true)
						setAlertColor('primary')
						setAlertMsg(obj.data.msg)
						setOpenError(true)
					} else {
						setAlertColor('danger')
						setAlertMsg(obj.data.error_msg)
						setOpenError(true)
					}
			}
    } 
	}
  
  return (
    <>
			<MDBContainer className="py-2 h-100 mt-4">
				<MDBRow>
					<MDBCol md="3"/>
					<MDBCol md="6">
						<BorderCol headline="Återställ lösenord" className='col-md-12 col-sm-12 square border border-primary rounded-6 p-4 mx-1 position-relative'>
						<MDBValidation onSubmit={changePassword} className='row g-3'>
							<MDBValidationItem feedback={pwFeedback} invalid>
								<MDBInput
									name='newPassword'
									onChange={onChange}
									id='newPassword'
									required
									label='Nytt lösenord'
									type="password"
								/>
							</MDBValidationItem>
							<MDBValidationItem feedback={pwFeedback} invalid>
								<MDBInput
									name='newPassword2'
									onChange={onChange}
									id='newPassword2'
									required
									label='Återupprepa nytt lösenord'
									type="password"
								/>
							</MDBValidationItem>
							<MDBAlert open={openError} className='mt-3 w-100' color={alertColor}>
								{alertMsg} {pwChanged ? <a href='/login-register'><u>Logga in.</u></a> : null}
							</MDBAlert>
							<div className='col-12 text-center'>
								<MDBBtn type='submit'>Återställ lösenord</MDBBtn>
							</div>
						</MDBValidation>
						</BorderCol>
					</MDBCol>
					<MDBCol md="3"/>
				</MDBRow>
			</MDBContainer>
    </>
  );
}