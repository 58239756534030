

function get_host_api() {
  //window.location.protocol, window.location.hostname
  const hostname = localStorage.getItem("hostname");
  if (hostname) {
    return hostname + '/api/';
  }
console.log('window.location.hostname', window.location.hostname);
  if (window.location.hostname==='localhost') {
    // return 'http://localhost/api/';
     return 'https://dev.svsf-ta.se/api/';
  } else {
    return `${window.location.protocol}//${window.location.hostname}/api/`
  }
}

export async function fetch_json(end_point, method = 'GET') {

  const myheaders = {
    'Authorization': 'Bearer ' + localStorage.getItem("token"),
    'Content-Type': 'application/json'
  };
  
  const response = await fetch(get_host_api() + end_point,
  {
    headers: myheaders,
    method: method,
    //mode:'cors',
  });
  if (!response.ok) {
    //throw new Error(
    // `This is an HTTP error: The status is ${response.status}`
    //);
  }
  const obj = await response.json();
  return obj;
}

/*
export async function fetch_json2(p_end_point, p_method, p_body) {
  console.log('ffffffff');
  const myheaders = {
    //'Authorization': 'Bearer my-token',
    //'Content-Type': 'application/json'
  };


  const response = await fetch("https://dev.svsf-ta.se/api/" + p_end_point,
    {
      //mode:'no-cors',
      //headers: {
      //  'Content-Type': 'application/json',
       // 'Access-Control-Allow-Origin': '*'
      //},
      //'Access-Control-Allow-Origin': '*'
      //'APPLICATION': "Bearer eyAiYWxnIjogIkhTMjU2IiwgInR5cCI6ICJKV1QiIH0.eyAiaWF0IjogMTU4MTk1NTY2NiwgImlzcyI6ICJBQkIgUm9ib3RpY3MgU2VydmljZSBQb3J0YWwiLCAic3ViIjogInJvcGFwaSIsICJqdGkiOiAicmVzdF9jb25uZWN0ZWRzZXJ2aWNlIiwgInVzZXJpZCI6ICJTRV9SQVdBOSJ9.FfMBzO3CITcVGMazj1s5n69JbuFK6HWHPiqwJSC3u3E"},
      //headers: myheaders,
      method: p_method,
      body: JSON.stringify(p_body)
    })
    ;
  console.log('ffffffff2222');
  const obj = await response.json();
  console.log('resp3', response);
  if (!response.ok) {
    console.warn('ej OK!', response.status, response.statusText, obj);
    //throw new Error({
    //                status: response.status,
    //                msg: obj.message
    //              });
    throw new Error(`HTTP error: Status = ${response.status} : ${obj.message}`);
  }

  return obj;
}
*/

export async function fetch_get(p_end_point) {

  // console.log('fetch_get', p_end_point);

  const myheaders = {
    'Authorization': 'Bearer ' + localStorage.getItem("token"),
    'Content-Type': 'application/json'
  };

  const response = await fetch(get_host_api() + p_end_point,
    {
      headers: myheaders,
      method: 'GET',
      //mode:'cors',
    })
    ;

  const obj = await response.json();
  // console.log('resp_get', response);
  if (!response.ok) {
    console.warn('ej OK!', response.status, response.statusText, obj);
    //throw new Error({
    //                status: response.status,
    //                msg: obj.message
    //              });
    throw new Error(`HTTP error: Status = ${response.status} : ${obj.message}`);
  }

  return obj;
}

export async function fetch_post(p_end_point, p_body) {
  // console.log('fetch_post', p_end_point, p_body);



  const myheaders = {
    'Authorization': 'Bearer ' + localStorage.getItem("token"),
    'Content-Type': 'application/json'
  };

  const response = await fetch(get_host_api() + p_end_point,
    {
      headers: myheaders,
      method: 'POST',
      cache: "no-cache",
      body: JSON.stringify(p_body),
      //mode:'no-cors',
    })
    ;

  const obj = await response.json();
  console.log('resp2', response);
  if (!response.ok) {
    console.warn('ej OK!', response.status, response.statusText, obj);
    //throw new Error({
    //                status: response.status,
    //                msg: obj.message
    //              });
    throw new Error(`HTTP error: Status = ${response.status} : ${obj.message}`);
  }

  return obj;
}

export async function fetch_post_document(p_end_point, p_body) {
  // console.log('fetch_post', p_end_point, p_body);



  const myheaders = {
    'Authorization': 'Bearer ' + localStorage.getItem("token"),
  };

  const response = await fetch(get_host_api() + p_end_point,
    {
      headers: myheaders,
      method: 'POST',
      cache: "no-cache",
      body: p_body,
      //mode:'no-cors',
    })
    ;

  const obj = await response.json();
  console.log('resp2', response);
  if (!response.ok) {
    console.warn('ej OK!', response.status, response.statusText, obj);
    //throw new Error({
    //                status: response.status,
    //                msg: obj.message
    //              });
    throw new Error(`HTTP error: Status = ${response.status} : ${obj.message}`);
  }

  return obj;
}


// Ej testad <rawa 20240201>
export async function fetch_post_multipart_form_data(p_end_point, p_body) {
  // console.log('fetch_post', p_end_point, p_body);



  const myheaders = {
    'Authorization': 'Bearer ' + localStorage.getItem("token"),
    'Content-Type': 'multipart/form-data'
  };

  const response = await fetch("https://dev.svsf-ta.se/api/" + p_end_point,
    {
      headers: myheaders,
      method: 'POST',
      cache: "no-cache",
      body: p_body,
      //mode:'no-cors',
    })
    ;

  const obj = await response.json();
  console.log('resp2', response);
  if (!response.ok) {
    console.warn('ej OK!', response.status, response.statusText, obj);
    //throw new Error({
    //                status: response.status,
    //                msg: obj.message
    //              });
    throw new Error(`HTTP error: Status = ${response.status} : ${obj.message}`);
  }

  return obj;
}

export async function fetch_delete(p_end_point, p_body) {
  console.log('fetch_delete', p_end_point, p_body);



  const myheaders = {
    'Authorization': 'Bearer ' + localStorage.getItem("token"),
    'Content-Type': 'application/json'
  };

  const response = await fetch("https://dev.svsf-ta.se/api/" + p_end_point,
    {
      headers: myheaders,
      method: 'DELETE',
      cache: "no-cache",
      body: JSON.stringify(p_body),
      //mode:'no-cors',
    })
    ;

  const obj = await response.json();
  console.log('resp2', response);
  if (!response.ok) {
    console.warn('ej OK!', response.status, response.statusText, obj);
    //throw new Error({
    //                status: response.status,
    //                msg: obj.message
    //              });
    throw new Error(`HTTP error: Status = ${response.status} : ${obj.message}`);
  }

  return obj;
}

