import React,{ useMemo, useState, useEffect } from 'react';
import { useLoaderData } from "react-router-dom";
import {    
	MDBCol,
	MDBRow,
	MDBContainer,
	MDBPopover,
	MDBPopoverHeader,
	MDBPopoverBody,
	MDBIcon,
	MDBBadge,
	MDBCardText,
	MDBListGroupItem,
	MDBBtn,
	MDBBtnGroup,
	MDBListGroup
} from 'mdb-react-ui-kit';
import {MRT_Localization_SV} from 'material-react-table/locales/sv'
import {
	MaterialReactTable,
	useMaterialReactTable,
	MRT_GlobalFilterTextField,
  MRT_ToggleFiltersButton,
	MRT_ToolbarAlertBanner,
} from 'material-react-table';
import {
	keepPreviousData,
	useQuery,
} from '@tanstack/react-query';
import {
	Box
} from '@mui/material';
import KansliSkyttekortModal from "./KansliSkyttekortModal"
import KansliLicenseModal from './KansliLicenseModal';
import EditSkyttekortModal from '../../skyttekort/EditSkyttekortModal';
import EditLicenseModal from '../../license/EditLicenseModal';

export default function AssociationLicenses() {

	const loadObject = useLoaderData();
	const sportList = loadObject.sportList.data;
	const [columnFilters, setColumnFilters] = useState([]);
	const [selectedRows, setSelectedRows] = useState([])
	const [rowSelection, setRowSelection] = useState({})
  const [globalFilter, setGlobalFilter] = useState('');
	const [loading, setLoading] = useState(false)
  const [sorting, setSorting] = useState([]);
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 50,
  });
	const [addSkyttekortModal, setAddSkyttekortModal] = useState(false)
	const [addLicenseModal, setAddLicenseModal] = useState(false)
	const [editSkyttekortModal, setEditSkyttekortModal] = useState(false);
	const [editLicenseModal, setEditLicenseModal] = useState(false);
	const [selectedUser, setSelectedUser] = useState(null);

	const toggleEditSkyttekortModal = (user) => {
		setSelectedUser(user)
		setEditSkyttekortModal(!editSkyttekortModal)
	};

	const toggleEditLicenseModal = (user) => {
		setSelectedUser(user)
		setEditLicenseModal(!editLicenseModal)
	};

	const toggleSkyttekortModal = () => {
		setAddSkyttekortModal(!addSkyttekortModal)
	};

	const toggleLicenseModal = () => {
		setAddLicenseModal(!addLicenseModal)
	};

	function listSports(sportString){
		const sportArr = sportString.split(',');
		return sportArr.map(sportID => {
			const sportText = sportList.find(({ value }) => value === sportID)
			if(sportText){
				return <MDBCardText key={sportText.value} className="text-muted">{sportText.text}</MDBCardText>
			}
		})
	}

	const tableColumns = useMemo(
    () => [
			{
        accessorKey: 'first_name',
        header: 'Förnamn',
        size: 150,
      },
			{
        accessorKey: 'last_name',
        header: 'Efternamn',
        size: 150,
      },
			{
        accessorKey: 'birth_date',
        header: 'Födelsedatum',
        size: 50,
      },
			{
        accessorKey: 'iid_nr',
        header: 'IID-nr',
        size: 50,
      },
			{
        accessorKey: 'forening',
        header: 'Förening',
        size: 50,
      },
			{
				accessorFn: (originalRow) => (originalRow.skyttekort_grenar !== null ? 'true' : 'false'),
				id: 'skyttekort_grenar',
				header: 'Skyttekort',
				size: 50,
				filterVariant: 'checkbox',
				Cell: ({ cell,row }) => (
					<>
						{cell.getValue() === 'true' ? 
							<MDBPopover
								poperStyle={{width:150}}
								dismiss
								tag='a'
								style={{ cursor: 'pointer'}}
								placement='left'
								btnChildren={<MDBBadge className="mx-2" pill color='success' light><MDBIcon fas icon="check" /></MDBBadge>}
							>
							<MDBPopoverHeader>Grenar</MDBPopoverHeader>
							<MDBPopoverBody>
								{row.original.skyttekort_grenar ? listSports(row.original.skyttekort_grenar) : null}
							</MDBPopoverBody>
							</MDBPopover> : <MDBBadge className="mx-2" pill color='danger' light><MDBIcon fas icon="times" /></MDBBadge>}
					</>
				)
			},
			{
				accessorFn: (originalRow) => (originalRow.activ_lic === 'true' ? 'true' : 'false'),
				id: 'activ_lic',
				header: 'Tävlingslicens',
				size: 50,
				filterVariant: 'checkbox',
				Cell: ({ cell,row }) => (
					<>
						{cell.getValue() === 'true' ? 
							<MDBPopover
								poperStyle={{width:150}}
								dismiss
								tag='a'
								style={{ cursor: 'pointer'}}
								placement='left'
								btnChildren={<MDBBadge className="mx-2" pill color='success' light><MDBIcon fas icon="check" /></MDBBadge>}
							>
							<MDBPopoverHeader>Grenar</MDBPopoverHeader>
							<MDBPopoverBody>
								{row.original.skyttekort_grenar ? listSports(row.original.skyttekort_grenar) : null}
							</MDBPopoverBody>
							</MDBPopover> : <MDBBadge className="mx-2" pill color='danger' light><MDBIcon fas icon="times" /></MDBBadge>}
					</>
				)
			},
			{
        accessorKey: 'expired_date',
        header: 'Giltlig tom.',
        size: 50
      }
    ],
    [],
  );

  const {
    data: { data = [], meta } = {},
    isError,
    isRefetching,
    isLoading
  } = useQuery({
    queryKey: [
      'table-data',
      columnFilters,
      globalFilter,
      pagination.pageIndex,
      pagination.pageSize,
      sorting,
			addSkyttekortModal,
			addLicenseModal,
			editLicenseModal,
			editSkyttekortModal
    ],
    queryFn: async () => {
      const fetchURL = new URL(
        '/api/data/users_lic_skyttekort/',
        process.env.NODE_ENV === 'production'
          ? 'https://dev.svsf-ta.se'
          : 'https://dev.svsf-ta.se',
      );

      fetchURL.searchParams.set(
        '_skip',
        `${pagination.pageIndex * pagination.pageSize}`,
      );

			function filterFunction(){
				let filterString = ""
				if(globalFilter && globalFilter.length > 0 ){
					filterString = filterString.concat("(first_name LIKE '%" + 
						globalFilter + "%' OR last_name LIKE '%" + 
						globalFilter + "%' OR user_fullname LIKE '%" + 
						globalFilter + "%' OR birth_date LIKE '%" + 
						globalFilter + "%' OR iid_nr LIKE '%" +
						globalFilter + "%')")
					if(columnFilters.length > 0){
						filterString = filterString.concat(" AND ")
					}
				}
				if(columnFilters.length > 0){
					filterString = filterString.concat("(")
					columnFilters.map((col, index) => {
						let searchParam = " LIKE '%" + col.value + "%'"
						if(col.id === "skyttekort_grenar"){
							if(col.value === "true"){
								if(index == 0){
									filterString = filterString.concat(col.id + " IS NOT NULL")
								} else {
									filterString = filterString.concat(" AND " + col.id + " IS NOT NULL")
								}
							} else {
								if(index == 0){
									filterString = filterString.concat(col.id + " IS NULL")
								} else {
									filterString = filterString.concat(" AND " + col.id + " IS NULL")
								}
							}
						} else {
							if(index == 0){
								filterString = filterString.concat(col.id + searchParam)
							} else {
								filterString = filterString.concat(" AND " + col.id + searchParam)
							}
						}
					})
					filterString = filterString.concat(")")
				}
				return filterString
			}
			
      fetchURL.searchParams.set('_top', `${pagination.pageSize}`);
			if(globalFilter || columnFilters.length > 0){
				fetchURL.searchParams.set('_filter', filterFunction());
			}
			if(sorting.length > 0){
				fetchURL.searchParams.set('_orderby', sorting[0].id + " " + (sorting[0].desc ? "desc" : ""));
			}

      const response = await fetch(fetchURL.href);
      const json = await response.json();
      return json;
    },
    placeholderData: keepPreviousData,
  });

  const table = useMaterialReactTable({
    columns: tableColumns,
    data,
    initialState: { 
			showColumnFilters: false, 
			showGlobalFilter: true,
			columnPinning: {
        right: ['mrt-row-actions'],
      },
		},
		enableRowActions: true,
    manualFiltering: true,
    manualPagination: true,
    manualSorting: true,
    muiToolbarAlertBannerProps: isError
      ? {
          color: 'error',
          children: 'Error loading data',
        }
      : undefined,
		renderRowActionMenuItems: ({ row, closeMenu }) => [
			<div key={row.id} style={{ minWidth: '8rem' }}>
					<MDBBtnGroup className="w-100" shadow='0' vertical aria-label='Vertical button group'>
						<MDBBtn className="p-2" onClick={() => (toggleEditSkyttekortModal(row.original), closeMenu())} color='link'>Hantera skyttekort</MDBBtn>
						<MDBBtn className="p-2" onClick={() => (toggleEditLicenseModal(row.original), closeMenu())} color='link'>Hantera licenser</MDBBtn>
					</MDBBtnGroup>
			</div>
			,
		],
    onColumnFiltersChange: setColumnFilters,
    onGlobalFilterChange: setGlobalFilter,
    onPaginationChange: setPagination,
    onSortingChange: setSorting,
		enableRowSelection: true,
		onRowSelectionChange: setRowSelection,
		enableCellActions: true,
    muiSelectCheckboxProps: { color: 'secondary' },
		enableColumnActions: false,
		enableFullScreenToggle: false,
		getRowId: (originalRow) => originalRow.user_key_id,
		localization:MRT_Localization_SV,
    rowCount: meta?.rowcount_total ?? 0,
    state: {
      columnFilters,
      globalFilter,
      isLoading,
      pagination,
      showAlertBanner: isError,
      showProgressBars: isRefetching,
      sorting,
			rowSelection
    },
		renderTopToolbar: ({ table }) => {
			return (
        <Box
          sx={{
						padding: '16px',
            display: 'flex',
            gap: '0.5rem',
            p: '8px',
            justifyContent: 'space-between',
          }}
        >
          <Box sx={{ display: 'flex', gap: '0.5rem', alignItems: 'center' }}>
            <MRT_GlobalFilterTextField table={table} />
            <MRT_ToggleFiltersButton table={table} />
						<MRT_ToolbarAlertBanner table={table} />
          </Box>
					<Box className="me-1">
						<MDBBtn className='m-2' disabled={selectedRows.length === 0 || loading ? true : false} onClick={() => toggleSkyttekortModal()}>Lägg till skyttekort valda medlemmar</MDBBtn>
						<MDBBtn className='m-2' disabled={selectedRows.length === 0 || loading ? true : false} onClick={() => toggleLicenseModal()}>Lägg till licens valda medlemmar</MDBBtn>
					</Box>
        </Box>

      );
		},
  });

	useEffect(() => {
		setSelectedRows(table.getSelectedRowModel().rows)
	}, [rowSelection]);

	return (
		<>
			<MDBContainer className="py-4">
				<h3>Skyttekort & Tävlingslicens</h3>
				<hr/>
				<MDBRow>
					<MDBCol size="12" className="mb-3">
						<MaterialReactTable table={table} />
					</MDBCol>
					{(selectedRows !== null && addSkyttekortModal) ? <KansliSkyttekortModal sentUsers={selectedRows} show={addSkyttekortModal} close={() => setAddSkyttekortModal(false)}/> : null}
					{(selectedRows !== null && addLicenseModal) ? <KansliLicenseModal sentUsers={selectedRows} show={addLicenseModal} close={() => setAddLicenseModal(false)}/> : null}
					{(selectedUser !== null && editSkyttekortModal) ? <EditSkyttekortModal sentUser={selectedUser} show={editSkyttekortModal} close={() => setEditSkyttekortModal(false)}/> : null}						
					{(selectedUser !== null && editLicenseModal) ? <EditLicenseModal sentUser={selectedUser} show={editLicenseModal} close={() => setEditLicenseModal(false)}/> : null}						
				</MDBRow>
			</MDBContainer>
		</>
          );
}