import { MDBContainer } from "mdb-react-ui-kit";

export default function PaymentError() {
      //
   return (
    <MDBContainer className="py-4">
        <div className='d-flex justify-content-center'>
          Något gick fel!
        </div>
    </MDBContainer>
   );
}