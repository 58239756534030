import React, { useEffect,useState, useMemo } from 'react';
import {    
  MDBBtn,
	MDBRow,
	MDBSpinner,
	MDBIcon,
	MDBModal,
	MDBModalBody,
	MDBModalContent,
	MDBModalTitle,
	MDBModalFooter,
	MDBModalHeader,
	MDBModalDialog,
	MDBAlert,
	MDBBadge,
	MDBPopconfirm,
	MDBPopconfirmMessage,
	MDBCol,
	MDBSelect
 } from 'mdb-react-ui-kit';
 import {
	MRT_Table,
	useMaterialReactTable,
} from 'material-react-table';
 import { fetch_get } from '../../../services/common';
 import { EditLicense } from './service/EditLicense';
import { Box } from '@mui/material';
import { AddLicense } from './service/AddLicense';

export default function EditLicenseModal(params){
	
	const [loading, setLoading] = useState(false);
	const user = params.sentUser;
	const [licenseEdited, setLicenseEdited] = useState(false)
	const [licenseAdded, setLicenseAdded] = useState(false)
	const [licenseError, setLicenseError] = useState(false)
	const [asyncData, setAsyncData] = useState([]);
	const [changeMade, setChangeMade] = useState(false)

	function getBadge(status){
		if(status === "ACTIVE"){
			return <MDBBadge data-bs-toggle="tooltip" data-bs-placement="top" title="Aktiv" className="mx-2" pill color='success' light><MDBIcon fas icon="check" /></MDBBadge> 
		}
		if(status === "DELETED"){
			return <MDBBadge data-bs-toggle="tooltip" data-bs-placement="top" title="Raderad" className="mx-2" pill color='danger' light><MDBIcon fas icon="times" /></MDBBadge>
		}
		if(status === "PENDING"){
			return <MDBBadge data-bs-toggle="tooltip" data-bs-placement="top" title="Förestående" className="mx-2" pill color='warning' light><MDBIcon far icon="clock" /></MDBBadge>
		}
		if(status === "INACTIVE"){
			return <MDBBadge data-bs-toggle="tooltip" data-bs-placement="top" title="Utången" className="mx-2" pill color='warning' light><MDBIcon far icon="calendar-check" /></MDBBadge>
		}
		if(status === "LOCKED"){
			return <MDBBadge data-bs-toggle="tooltip" data-bs-placement="top" title="Spärrad" className="mx-2" pill color='danger' light><MDBIcon fas icon="ban" /></MDBBadge>
		}
		if(status === "PAUSED"){
			return <MDBBadge data-bs-toggle="tooltip" data-bs-placement="top" title="Spärrad" className="mx-2" pill color='warning' light><MDBIcon fas icon="pause" /></MDBBadge>
		}
		if(status === "EXPIRED"){
			return <MDBBadge data-bs-toggle="tooltip" data-bs-placement="top" title="Utången" className="mx-2" pill color='warning' light><MDBIcon far icon="calendar-check" /></MDBBadge>
		}
	}

	const tableColumns = useMemo(
    () => [
			{
        accessorKey: 'lic_name',
        header: 'License',
        size: 50
      },
			{
        accessorKey: 'start_date',
        header: 'Startdatum',
        size: 50
      },
			{
        accessorKey: 'end_date',
        header: 'Slutdatum',
        size: 50
      },
			{
        accessorKey: 'status',
        header: 'Status',
        size: 50,
				Cell: ({ cell }) => (
					<>
						{getBadge(cell.getValue())}						
					</>
				)
      },
    ],
    [],
  );

	const addLicense = () => {

		AddLicense(user.user_key_id, getLatestStartDate(), getEndDate()).then(obj => {
			if(obj.status == "OK"){
				setLicenseAdded((prev) => !prev)
				setChangeMade(!changeMade)
			} else {
				setLicenseError((prev) => !prev)
			}
		});
		
	}

	const editLicense = (status,licenseID) => {

		EditLicense(user.key_id,licenseID,status).then(obj => {
			if(obj.status == "OK"){
				setLicenseEdited((prev) => !prev);
				setChangeMade(!changeMade)
			} else {
				setLicenseError((prev) => !prev)
			}
		});
		
	}

	useEffect(() => {
		setLoading(true)
		fetch_get("app/user/licenser/" + user.user_key_id)
			.then((data) =>
				setTimeout(() => {
					setAsyncData(data.data)
					setLoading(false)
				}, 3000)
			);
	}, [changeMade]);

	const getLatestStartDate = () => {
		const currentDate = newDate(new Date(new Date().getFullYear(),new Date().getMonth(),new Date().getDate()));
    let latestDate = currentDate
    if(asyncData != null && asyncData.length > 0){
      asyncData.map(license => {
        if(license.status !== "INACTIVE"){
          if(formatDate(latestDate).getTime() < formatDate(license.end_date).getTime()){
            latestDate = license.end_date;
          }
        }
      });
    }
    let date = new Date(latestDate);
    if(latestDate != currentDate){
      date.setDate(date.getDate() + 1);
    }
    return newDate(date)
  }

	const getEndDate = () => {
		let date = new Date(getLatestStartDate())
		date.setFullYear(date.getFullYear() + 1);
		return newDate(date)
	}

	function padDigits(num) {
    return num.toString().padStart(2, '0');
  }
  
  function newDate(date) {
    return [
      date.getFullYear(),
      padDigits(date.getMonth() + 1),
      padDigits(date.getDate()),
    ].join('-');
  }

	function formatDate(date){
    if(date != null){return new Date(date.split("/").reverse().join("-"));}
  }

	const table = useMaterialReactTable({
    columns: tableColumns,
    data: asyncData,
		enableColumnActions: false,
		localization: {
			noRecordsToDisplay: "Licens saknas",
			actions: "Åtgärder"
		},
		initialState: { 
			columnPinning: {
        right: ['mrt-row-actions'],
      },
			
		},
		enableRowActions: true,
		renderRowActions: ({ row }) => (
			row.original.status === "ACTIVE" ?
			<Box>
				<MDBPopconfirm data-bs-toggle="tooltip" data-bs-placement="top" title="Inaktivera licens" modal color='none' className='m-1' style={{ color: 'red', background: 'white', border: 'none' }} btnChildren={
					
						<MDBIcon far icon="trash-alt" size='lg' />
					} onConfirm={() => editLicense("DELETED", row.original.id)}>
					<MDBPopconfirmMessage>Ta bort licens?</MDBPopconfirmMessage>
				</MDBPopconfirm>
				<MDBPopconfirm data-bs-toggle="tooltip" data-bs-placement="top" title="Spärra licens" modal color='none' className='m-1' style={{ color: 'black', background: 'white', border: 'none' }} btnChildren={
					
					<MDBIcon fas icon="ban" size='lg' />
				} onConfirm={() => editLicense("LOCKED", row.original.id)}>
				<MDBPopconfirmMessage>Spärra licens?</MDBPopconfirmMessage>
			</MDBPopconfirm>
			</Box> :
			<Box>
				<MDBPopconfirm data-bs-toggle="tooltip" data-bs-placement="top" title="Aktivera licens" modal color='none' className='m-1' style={{ color: '#0065A4', background: 'white', border: 'none' }} btnChildren={
					
						<MDBIcon fas icon="plus" size='lg' />
					} onConfirm={() => editLicense("ACTIVE", row.original.id)}>
					<MDBPopconfirmMessage>Återaktivera licens?</MDBPopconfirmMessage>
				</MDBPopconfirm>
			</Box>
		)
  });

    return <>
						<MDBAlert
							color='primary'
							autohide
							position='top-right'
							delay={4000}
							appendToBody
							open={licenseEdited}
							onClose={() => setLicenseEdited(false)}
						>
							Licens har ändrats.
						</MDBAlert>
						<MDBAlert
							color='primary'
							autohide
							position='top-right'
							delay={4000}
							appendToBody
							open={licenseAdded}
							onClose={() => setLicenseAdded(false)}
						>
							Licens har lagts till för {user.first_name} {user.last_name}.
							Gäller från {getLatestStartDate()} till {getEndDate()}.
						</MDBAlert>
						<MDBAlert
							color='danger'
							autohide
							position='top-right'
							delay={4000}
							appendToBody
							open={licenseError}
							onClose={() => setLicenseError(false)}
						>
							Något gick fel vid ändring av licens.
						</MDBAlert>
						<MDBModal onClose={params.close} open={params.show} tabIndex='-1'>
							<MDBModalDialog size="lg">
								<MDBModalContent>
									<MDBModalHeader>
										<MDBModalTitle>Hantera licens</MDBModalTitle>
										<MDBBtn className='btn-close' color='none' onClick={params.close}></MDBBtn>
										</MDBModalHeader>
											<MDBModalBody>
											{loading ? 
												<MDBRow className="d-flex justify-content-center py-5">
													<MDBSpinner color='primary'>
														<span className='visually-hidden'>Loading...</span>
													</MDBSpinner>
												</MDBRow> :
												<>
													<MRT_Table table={table} />
													<hr></hr>
													<MDBPopconfirm  modal btnChildren='Lägg till licens' onConfirm={() => addLicense()}>
														<MDBPopconfirmMessage>Lägg till 12-månader licens till {user.user_fullname}?</MDBPopconfirmMessage>
													</MDBPopconfirm>
												</>
											}
											</MDBModalBody>
										<MDBModalFooter>
										<MDBBtn color='secondary' onClick={params.close}>
											Stäng
										</MDBBtn>
									</MDBModalFooter>
								</MDBModalContent>
							</MDBModalDialog>
						</MDBModal>
          </>
  
}