import { fetch_post } from "../../../../services/common";

export async function EditSportgren(id, form){
	
	console.log(form)
	const response = await fetch_post("app/sportgren/do_submit/" + id, {
    action: "edit_sportgren",
    db_name: form.name,
		db_default_fee: form.default_fee
	})
		
	return  response;
}