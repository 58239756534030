import { useNavigate, useLoaderData } from "react-router-dom";
import React,{ useState, useMemo, useEffect } from 'react';
import {    
  MDBBtn,
  MDBTable, 
  MDBTableHead, 
  MDBTableBody,
  MDBBadge,
	MDBSwitch,
	MDBCol,
	MDBRow,
	MDBContainer,
	MDBAlert
 } from 'mdb-react-ui-kit';
 import {MRT_Localization_SV} from 'material-react-table/locales/sv'
 import {
	 MaterialReactTable,
	 useMaterialReactTable,
 } from 'material-react-table';
import { fetch_get } from "../../../services/common";
import jwt_decode from "jwt-decode";
import { BorderCol } from "../../modules/BorderCol";

export async function loader({ params }) {
	const data = localStorage.getItem("token");
	var decoded = "";
	if (data) {
		decoded = jwt_decode(data);
	}

	const obj = {
		user: await fetch_get("app/user/all/" + params.key_id),
		guest: decoded.kid == params.key_id ? false : true
	}
		return obj;
}

export default function UserLicense(){

	const loadObject = useLoaderData();
	const user = loadObject.user.data;
  const licenses = user.licenser;
	const grenar = user.skyttekort.map(kort => {
		return kort.gren_name + " "
	});
	const grenar_ordered = grenar.sort();
	const [tableData, setTableData] = useState(licenses); 
	const navigate = useNavigate();
	const [showAll, setShowAll] = useState(false);
	const skyttekortExists = user.skyttekort.length > 0 ? true : false
	const showMissingSkyttekortAlert = user.skyttekort.length > 0 ? false : true

	useEffect(() => {
  	
		if(!showAll){
			const todaysDate = new Date().getTime();
			const filterData = tableData.filter((row) => (row.status == "ACTIVE" || row.status == "PENDING"))
			setTableData(filterData.map(data => {
				return { ...data, gren_name: grenar_ordered}
			}))
		} else {
			setTableData(licenses.map(data => {
				return { ...data, gren_name: grenar_ordered}
			}))
		}

  }, [showAll]);

	const tableColumns = useMemo(
    () => [
			{
        accessorKey: 'lic_name', //access nested data with dot notation
        header: 'Namn',
        size: 150,
      },
			{
        accessorKey: 'gren_name', //access nested data with dot notation
        header: 'Gren',
        size: 150,
      },
      {
        accessorKey: 'start_date', //access nested data with dot notation
        header: 'Startdatum',
        size: 150,
      },
			{
        accessorKey: 'end_date', //access nested data with dot notation
        header: 'Giltlig',
        size: 50,
      },
			
    ],
    [licenses],
  );
	
	const table = useMaterialReactTable({
    columns: tableColumns,
    data: tableData,
    enableColumnFilters: false,
		enableColumnActions: false,
		enableDensityToggle: false,
		enableFullScreenToggle: false,
		enableHiding: false,
		enableFilters: false,
		localization:MRT_Localization_SV,
		renderTopToolbarCustomActions: () => (
			<MDBSwitch id='flexSwitchCheckDefault' onChange={() => setShowAll(!showAll)} label='Visa alla tävlingslicenser' />
		)
  });
  
  if(licenses.length > 0){
    return (
			<>
				<MDBContainer className="py-4">
					{showMissingSkyttekortAlert ? <MDBAlert
						className='d-flex justify-content-between'
						defaultOpen
						autohide 
						delay={10000}
						color='warning'
						dismissBtn
						position='bottom-center'>
						<p className='mb-0'>
						Skyttekort saknas för {user.first_name + " " + user.last_name}.
						</p>
					</MDBAlert> : null}
					<h3>Tävlingslicenser</h3>
					<hr/>
					<MDBRow className='d-flex justify-content-center'>
						<MDBCol md="10" className="mb-3">
							<BorderCol headline="Mina tävlingslicenser" className='col-md-12 col-sm-12 square border border-primary rounded-6 p-4 mx-1 position-relative'>
								<MaterialReactTable table={table} />
							</BorderCol>
						</MDBCol>
					</MDBRow>
					<MDBRow className='d-flex justify-content-center'>
						<MDBCol className="mt-3 text-center">
							<MDBBtn color='primary' size='sm' disabled={!skyttekortExists} onClick={() => {navigate("/license-options",{state: [user]})}}>Köp licens</MDBBtn>						
						</MDBCol>
					</MDBRow>
				</MDBContainer>
			</>
		);
  } else {
		return (<>
			<MDBContainer className="py-4">
				{showMissingSkyttekortAlert ? <MDBAlert
					className='d-flex justify-content-between'
					defaultOpen
					autohide 
					delay={10000}
					color='warning'
					dismissBtn
					position='bottom-center'>
					<p className='mb-0'>
					Skyttekort saknas för {user.first_name + " " + user.last_name}.
					</p>
				</MDBAlert> : null}
				<h3>Tävlingslicenser</h3>
				<hr/>
				<p align='middle'>Inga licenser finns att visa.</p>
				<MDBRow>
					<MDBCol size="5"/>
					<MDBCol size="2" className="mt-3 text-center">
						<MDBBtn color='primary' size='sm' disabled={!skyttekortExists} onClick={() => {navigate("/license-options",{state: [user]})}}>Köp licens</MDBBtn>						
					</MDBCol>
				</MDBRow>
			</MDBContainer>
		</>
		)
	}

 
}