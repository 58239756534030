import { useLoaderData,useNavigate } from "react-router-dom";
import React, { useState, useRef } from 'react';
import {    
  MDBRow, 
  MDBCol, 
  MDBContainer,
	MDBValidation,
	MDBValidationItem,
	MDBInput,
	MDBBtn,
	MDBAlert,
	MDBInputGroup,
	MDBPopconfirm,
	MDBPopconfirmMessage
 } from 'mdb-react-ui-kit';
import { BorderCol } from "../../modules/BorderCol";
import { fetch_get } from "../../../services/common";
import jwt_decode from "jwt-decode";
import { fetch_post_document } from "../../../services/common";
import Compressor from "compressorjs";

export async function loader({ params }) {
	const data = localStorage.getItem("token");
	var decoded = "";
	if (data) {
		decoded = jwt_decode(data);
	}

	const obj = {
		user: await fetch_get("app/user/all/" + params.key_id),
		guest: decoded.kid == params.key_id ? false : true
	}
		return obj;
}

export default function UserSettings() {

	const loadObject = useLoaderData();
  const user = loadObject.user.data;
	const [pwFeedback, setPwFeedback] = useState("Ange ett lösenord");
	const [openError, setOpenError] = useState(false);
	const [profilePicture, setProfilePicture] = useState('https://dev.svsf-ta.se/api/app/document/get_document/' + user.profile_picture_link);
	const [alertMsg, setAlertMsg] = useState("Nya lösenorden matchar inte.")
	const [alertColor, setAlertColor] = useState('warning')
	const inputRef = useRef(null);
	const [fileSelected, setFileSelected] = useState(false)
	const [loading, setLoading] = useState(false)

	const [passworldFormValue, setPasswordFormValue] = useState({
		oldPassword: '',
		newPassword: '',
		newPassword2: ''
	})

	const onChange = (e) => {
		setOpenError(false)
    setPasswordFormValue({ ...passworldFormValue, [e.target.name]: e.target.value });
		if((passworldFormValue.newPassword.length == 0) && passworldFormValue.newPassword2.length == 0){
			setPwFeedback("Ange ett lösenord")
		}
		console.log(profilePicture)
  };

	const changePassword = async (e) => {
		e.preventDefault();

		//Minst 8 tecken, en stor och en liten och en siffra.
		const regexp = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d\w\W]{8,}$/
		
    if (e.target.checkValidity()) {
			if(passworldFormValue.newPassword !== passworldFormValue.newPassword2){
				setAlertColor('warning')
				setAlertMsg("Nya lösenorden matchar inte.")
				setOpenError(true)
			}
			if(!regexp.exec(passworldFormValue.newPassword)){
				setAlertColor('warning')
				setAlertMsg("Lösenordet måste innehålla minst en liten och en stor bokstav och minst en siffra.")
				setOpenError(true)
			}
			if(regexp.exec(passworldFormValue.newPassword) && (passworldFormValue.newPassword == passworldFormValue.newPassword2)){
				const response = await fetch("https://dev.svsf-ta.se/api/auth/change-password", {
						//headers: headers,
						method: 'POST',
						body: JSON.stringify({
							"key_id": user.key_id,
							"old_password" : passworldFormValue.oldPassword,
							"new_password": passworldFormValue.newPassword}
						)
	
					});
					if (!response.ok) {
						
					}    
					const obj = await response.json();
					if(obj.status == "error"){
						setAlertColor('warning')
						setAlertMsg("Det gamla lösenordet är felaktigt.")
						setOpenError(true)
					} else {
						setAlertColor('primary')
						setAlertMsg("Lösenorder är bytt!")
						setOpenError(true)
					}
			}
    } 
	}

	const removeProfilePicture = async () => {
		const formData = new FormData();
		formData.append("action", "user_delete_profile_picture");
		formData.append("db_document_type_id", 1);

		const resJson = await fetch_post_document('app/user/do_document/' + user.key_id, formData);
		setProfilePicture('https://dev.svsf-ta.se/api/app/document/get_document/null')
	}

  const handleSubmit = async () => {
    inputRef.current?.files && setProfilePicture(URL.createObjectURL(inputRef.current.files[0]));
		const formData = new FormData();
    if (inputRef.current.files[0]) {
			setLoading(true)
			new Compressor(inputRef.current.files[0], {
				quality: 0.6, // 0.6 can also be used, but its not recommended to go below.
				success: async (compressedResult) => {
					formData.append(
						"my_file",
						compressedResult,
						compressedResult.name
					);
					formData.append("action", "user_add_profile_picture");
					formData.append("db_document_type_id", 1);
					formData.append("db_display_name", "profile_pic");

					console.log(formData)
		
					const resJson = await fetch_post_document('app/user/do_document/' + user.key_id, formData);
					setLoading(false)
				},
			});
    }
  };

	const uploadChange = () => {
		if(inputRef.current?.files[0] && inputRef.current?.files[0].size < 5000000){
			setFileSelected(true)
		} else {
			setFileSelected(false)
		}
  };
  
  return (
    <>
			<MDBContainer className="py-4">
				<h3>Inställningar</h3>
				<hr/>
				<MDBRow>
					<MDBCol md="4">
						<BorderCol headline="Ändra lösenord" className='col-md-12 col-sm-12 square border border-primary rounded-6 p-4 mx-1 position-relative'>
						<MDBValidation onSubmit={changePassword} className='row g-3'>
							<MDBValidationItem feedback='Ange ett lösenord' invalid>
								<MDBInput
									name='oldPassword'
									onChange={onChange}
									id='oldPassword'
									required
									label='Gammalt lösenord'
									type="password"
								/>
							</MDBValidationItem>
							<MDBValidationItem feedback={pwFeedback} invalid>
								<MDBInput
									name='newPassword'
									onChange={onChange}
									id='newPassword'
									required
									label='Nytt lösenord'
									type="password"
								/>
							</MDBValidationItem>
							<MDBValidationItem feedback={pwFeedback} invalid>
								<MDBInput
									name='newPassword2'
									onChange={onChange}
									id='newPassword2'
									required
									label='Återupprepa nytt lösenord'
									type="password"
								/>
							</MDBValidationItem>
							<MDBAlert open={openError} className='mt-3 w-100' color={alertColor}>
								{alertMsg}
							</MDBAlert>
							<div className='col-12'>
								<MDBBtn type='submit'>Spara lösenord</MDBBtn>
							</div>
						</MDBValidation>
						</BorderCol>
					</MDBCol>
					<MDBCol md="6">
						<BorderCol headline="Profilbild" className='col-md-12 col-sm-12 square border border-primary rounded-6 p-4 mx-1 position-relative'>
							<MDBRow>
								<MDBInputGroup className='mb-3' onChange={uploadChange} col="3" label='Default file input example'>
									<input className="form-control form-control-undefined" type="file" id='customFile' ref={inputRef} />
									<MDBBtn outline type='submit' disabled={!fileSelected} onClick={handleSubmit}>Spara profilbild</MDBBtn>
								</MDBInputGroup>
								<div className="form-text">
									Tänk på att bilden är offentlig och kan ses av alla. Lägg inte upp bilder som kan uppfattas som stötande eller kränkande. Bilden får inte överstiga 5MB.
								</div>
								<MDBAlert open={loading} color='white' autohide position="bottom-right" delay={2000} appendToBody onClose={() => setLoading(false)}>
									<i className="fa-solid fa-floppy-disk me-3"></i>
								</MDBAlert>
							</MDBRow>
							<MDBRow>
								<div className='text-center'>
										<img src={profilePicture} alt="Avatar" className="my-3 rounded-3" style={{ width: '180px',height: '180px', objectFit: 'cover' }} />									
								</div>
							</MDBRow>
							{!profilePicture.endsWith("null") ? 
							<MDBRow>
								<MDBPopconfirm
									style={{ padding: '5px' }}
									color="none"
									modal
									btnChildren={<MDBBtn className='mx-2' color='tertiary' rippleColor='light'>
										<div className='text-center'>
											Ta bort profilbild
										</div>
									</MDBBtn>}
									cancelBtnText='Avbryt'
									onConfirm={() => removeProfilePicture()}
									cancelBtnClasses='btn-secondary'
									btnClassName='btn btn-link'
									title={"Ta bort dokument"}
								>
									<MDBPopconfirmMessage>
										Ta bort profilbild?
									</MDBPopconfirmMessage>
								</MDBPopconfirm>
							</MDBRow> : null}
						</BorderCol>
					</MDBCol>
				</MDBRow>
			</MDBContainer>
    </>
  );
}