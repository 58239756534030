import React, { useState } from "react";

import { Routes, Route, createBrowserRouter, createRoutesFromElements, useLoaderData, RouterProvider, Form } from "react-router-dom";
import {
  MDBContainer,
} from 'mdb-react-ui-kit';

import ErrorBoundary from './ErrorBoundary';
import ErrorPage from './components/views/ErrorPage';
import NotFound404 from "./components/views/NotFound404";
import ForgotPassword from "./components/views/ForgotPassword";
import LoginRegister from "./components/views/users/LoginRegister";
import Root, { loader as RootLoader } from "./components/views/Root";
import HomePage, { loader as HomePageLoader } from "./components/views/HomePage";
import RankingPage, { loader as RankingLoader } from "./components/views/Ranking/RankingPage";
import RankingPageList, { loader as RankingPageLoader } from "./components/views/Ranking/RankingPageList";
import AssociationRoot, { loader as associationRootLoader } from "./components/views/association/AssociationRoot";
import GrenHome, { loader as GrenHomeLoader } from "./components/views/gren/GrenHome";
import SettingsHome from "./components/views/settings/SettingsHome";
import SettingsRoot, { loader as SettingsRootLoader } from "./components/views/settings/SettingsRoot";
import EventsPage, { loader as EventsPageLoader } from "./components/views/events/EventsPage";
import MyEventCalendarAdmin, { loader as MyEventCalendarAdminLoader } from "./components/views/events/MyEventCalendarAdmin";
import EventNew, { action as EventNewAction, loader as EventNewLoader } from "./components/views/events/EventNew";
import EventNewEvenemang from "./components/views/events/EventNewEvenemang";
import EventEditStepper from "./components/views/events/EventEditStepper";
import EventEditStepper2 from "./components/views/events/EventEditStepper-2";
import EventEditSportgrenar, { loader as EventEditSportgrenarLoader } from "./components/views/events/EventEditSportgrenar";
import EventEditBetalning, { loader as EventEditBetalningLoader } from "./components/views/events/EventEditBetalning";
import EventEditLag from "./components/views/events/EventLagConfig";
import EventEditDokument from "./components/views/events/EventEditDokument";
import EventEditExtra from "./components/views/events/EventEditExtra";
import EventAnmalan, { loader as EventAnmalanLoader } from "./components/views/events/EventAnmalan";
import EventsRoot, { loader as EventsRootLoader } from "./components/views/events/EventsRoot";
import EventRoot, { loader as EventRootLoader } from "./components/views/events/EventRoot";
import EventSettingsDiscipline from "./components/views/settings/event/EventSettingsDiscipline";
import EventSettingsRoot from "./components/views/settings/event/EventSettingsRoot";
import EventSettingsHome from "./components/views/settings/event/EventSettingsHome";
import EventCategories, { loader as EventCategoriesLoader } from "./components/views/settings/event/EventCategories";
import EventOverview, { loader as EventOverviewLoader } from "./components/views/events/EventOverviewPage";

import EventOverviewAdmin, { loader as EventOverviewAdminLoader } from "./components/views/events/EventOverviewAdminPage";
import EventSportgrenarConfig, { loader as EventSportgrenarConfigLoader } from "./components/views/events/EventSportgrenarConfig";
import EventLagConfig from "./components/views/events/EventLagConfig";
import EventLagAnmalan from "./components/views/events/EventLagAnmalan";
import EventEditDeltagare from "./components/views/events/EventEditDeltagare";
import EventProgramConfig, { loader as EventProgramConfigLoader } from "./components/views/events/EventProgramConfig";
import EventStartlista from "./components/views/events/EventStartlista";
import EventResult from './components/views/events/EventResult';
import EventResultTV from './components/views/events/EventResultTV';
import EventDocuments, { loader as EventDocumentsLoader } from "./components/views/events/EventDocuments";
import EventReports, { loader as EventReportsLoader } from "./components/views/events/EventReports";


import DefEditSportgrenarKlasser from "./components/views/settings/event/DefEditSportgrenarKlasser";
import ListDistrict, { loader as listLoader, } from "./components/views/ListDistrict";
import AssociationDetails, { loader as associationLoader } from "./components/views/association/administration/AssociationDetails";
import UserDetails, { loader as UserDetailsLoader } from "./components/views/users/UserDetails";
import UserRoot, { loader as userRootLoader } from "./components/views/users/UserRoot";
import EditUser, { loader as editUserLoader } from "./components/views/EditUser";
import SearchUsers, { loader as searchUsersLoader } from "./components/views/SearchUsers";
import ShopSummary from "./components/views/license/ShopSummary";
import PaymentProcess from "./components/views/payment/PaymentProcess";
import PaymentSuccessful from "./components/views/payment/PaymentSuccessful";
import PaymentError from "./components/views/payment/PaymentError";
import LicenseStepper, { loader as LicenseShopLoader } from "./components/views/license/LicenseStepper";
import TestDocuments from "./components/views/TestDocuments";
import TesterPage from "./components/views/TesterPage";
import FacilitiesHome, { loader as FacilitiesHomeLoader } from "./components/views/association/facilities/FacilitiesHome";
import FacilityDetails, { loader as FacilityDetailsLoader } from "./components/views/association/facilities/FacilityDetails";
import FacilityForm, { loader as FacilityFormLoader } from "./components/views/association/facilities/FacilityForm";
import DistrictRoot, { loader as DistrictRootLoader } from "./components/views/district/DistrictRoot";
import DistrictAssociations from "./components/views/district/associations/DistrictAssociations";
import DistrictRoles from "./components/views/district/associations/DistrictRoles";
import WorkInProgress from "./components/views/WorkInProgress";
import ImportData from "./components/views/tools/ImportData";
import ResultInputTable from "./components/views/ResultInputTable";
import ResetPassword, { loader as ResetPasswordLoader } from "./components/views/users/ResetPassword";
import LicenseDisplayOptions, { loader as LicenseDisplayOptionsLoader } from "./components/views/license/LicenseDisplayOptions";
import UserEvents, { loader as userEventsLoader } from "./components/views/users/UserEvents";
import UserLicense, { loader as userLicenseLoader } from "./components/views/users/UserLicense";
import UserRoles, { loader as userRolesLoader } from "./components/views/users/UserRoles";
import UserPayments, { loader as userPaymentsLoader } from "./components/views/users/UserPayments";
import UserSettings, { loader as userSettingsLoader } from "./components/views/users/UserSettings";
import UserFamily, { loader as userFamilyLoader } from "./components/views/users/UserFamily";
import UserResults, { loader as userResultsLoader }  from "./components/views/users/UserResults";
import UserRanking, { loader as userRankingLoader }  from "./components/views/users/UserRanking";
import UserAssociation, { loader as userAssociationLoader }  from "./components/views/users/UserAssociation";
import AssociationUsers, { loader as associationUsersLoader } from "./components/views/association/administration/AssociationUsers";
import AssociationLicenses from "./components/views/association/administration/AssociationLicenses";
import SysAdminLogin, { loader as SysAdminLoginLoader } from "./components/views/users/SysAdminLogin";
import SysAdminLoginLink, { loader as SysAdminLoginLinkLoader } from "./components/views/users/SysAdminLoginLink";
import KansliEventType from "./components/views/kansli/settings/KansliEventType";
import KansliAssociations from "./components/views/kansli/associations/KansliAssociations";
import KansliAccounting from "./components/views/kansli/economy/KansliAccounting";
import KansliPaymentHistory from "./components/views/kansli/economy/KansliPaymentHistory";
import KansliFacility from "./components/views/kansli/facilities/KansliFacility";
import KansliRange from "./components/views/kansli/facilities/KansliRange";
import KansliEducation from "./components/views/kansli/members/KansliEducation";
import KansliLicenses from "./components/views/kansli/members/KansliLicenses";
import KansliRoles from "./components/views/kansli/members/KansliRoles";
import KansliMembers from "./components/views/kansli/members/KansliMembers";
import KansliUserLogin from "./components/views/kansli/members/KansliUserLogin";
import KansliStatistics from "./components/views/kansli/reports/KansliStatistics";
import KansliEventClass from "./components/views/kansli/settings/KansliEventClass";
import KansliLicenseSettings from "./components/views/kansli/settings/KansliLicenseSettings";
import KansliOrganizer from "./components/views/kansli/settings/KansliOrganizer";
import KansliSeries from "./components/views/kansli/settings/KansliSeries";
import KansliSport from "./components/views/kansli/settings/KansliSport";

import KansliRoot, { loader as kansliRootLoader } from "./components/views/kansli/KansliRoot";


function ProjectsPage() {
  return <Form method="post" action="fffff" />;
}

const router = createBrowserRouter([
  {
    path: "/",
    element: <Root />,
    loader: RootLoader,
     //errorElement: <NotFound404 />,
    children: [
      { index: true, element: <HomePage />, loader: HomePageLoader },
      {
        path: "ranking",
        element: <RankingPage />,
        loader: RankingLoader,
      },
      {
        path: "ranking-list/:id",
        element: <RankingPageList />,
        // loader: RankingPageLoader,

      },
      {
        path: "under-construction",
        element: <WorkInProgress />,
        //loader: shopSummaryLoader
      },
      {
        path: "import-data",
        element: <ImportData />,
        //loader: shopSummaryLoader
      },
      {
        path: "login-register",
        element: <LoginRegister />,
        //loader: page1Loader,
      },
      {
        path: "forgot-password",
        element: <ForgotPassword />,
        //loader: page1Loader,
      },
      {
        path: "password-reset/:key_id/:key_link",
        element: <ResetPassword />,
        loader: ResetPasswordLoader,
      },
      {
        path: "sysadmin-login/:user_key_id/:user_token/:sysadmin_key_id/:sysadmin_token",
        element: <SysAdminLogin />,
        loader: SysAdminLoginLoader,
      },
      {
        path: "sysadmin-login-link",
        element: <SysAdminLoginLink />,
        loader: SysAdminLoginLinkLoader,
      },
      {
        path: "event-list",
        element: <EventsPage />,
        // errorElement: <NotFound404 />,
      },
      {
        path: "list-association",
        element: <ListDistrict />,
        loader: listLoader,
      },
      {
        path: "district",
        element: <DistrictRoot />,
        loader: DistrictRootLoader,
				children: [
          { index: true, element: <DistrictAssociations />, loader: DistrictRootLoader, path: "associations" },
          {
            path: "roles",
            element: <DistrictRoles />, 
						loader: DistrictRootLoader
          },  
        ],  
      },
      {
        path: "team/user-admin/:id",
        element: <AssociationDetails />,
        loader: associationLoader,
      },
      {
        path: "team/facilities/:id",
        element: <FacilitiesHome />,
        loader: FacilitiesHomeLoader,
      },
      {
        path: "team/facilities/:association_id/facility/:facility_id",
        element: <FacilityDetails />,
        loader: FacilityDetailsLoader,
      },
      {
        path: "team/facilities/:association_id/facility/:facility_id/edit",
        element: <FacilityForm />,
        loader: FacilityFormLoader,
      },
      {
        path: "test-documents",
        element: <TestDocuments />
      },
      {
        path: "event-result",
        element: <ResultInputTable />
      },
      {
        path: "test-page",
        element: <TesterPage />
      },
      {
        path: "step-license",
        element: <LicenseStepper />,
        loader: LicenseShopLoader
      },
      {
        path: "license-summary",
        element: <ShopSummary />,
        //loader: shopSummaryLoader
      },
      {
        path: "process-payment",
        element: <PaymentProcess />,
        //loader: shopSummaryLoader
      },
      {
        path: "license-options",
        element: <LicenseDisplayOptions />,
        loader: LicenseDisplayOptionsLoader
      },
      {
        path: "payment-error",
        element: <PaymentError />,
        //loader: shopSummaryLoader
      },
      {
        path: "successful-payment",
        element: <PaymentSuccessful />,
        //loader: shopSummaryLoader
      },
      {
        path: "search-users",
        element: <SearchUsers />,
        loader: searchUsersLoader
      },
			{
        path: "association/:id",
        element: <AssociationRoot />,
				loader: associationRootLoader,
        children: [
          { index: true, element: <AssociationUsers />, loader: associationRootLoader, path: "members" },
          {
            path: "license",
            element: <AssociationLicenses />, 
						loader: associationRootLoader
          },  
        ],  
      },
			{
        path: "kansli",
        element: <KansliRoot/>,
        loader: kansliRootLoader,
				children: [
					{ index: true, element: <KansliMembers />, loader: kansliRootLoader, path: "members" },
          {
            path: "license",
            element: <KansliLicenses />,
						loader: kansliRootLoader
          }, 
					{
            path: "roles",
            element: <KansliRoles />,
						loader: kansliRootLoader
          }, 
					{
            path: "education",
            element: <KansliEducation />,
						loader: kansliRootLoader
          }, 
					{
            path: "sysadmin-login",
            element: <KansliUserLogin />,
						loader: kansliRootLoader
          }, 
					{
            path: "associations",
            element: <KansliAssociations />,
						loader: kansliRootLoader
          }, 
					{
            path: "facilities",
            element: <KansliFacility />,
						loader: kansliRootLoader
          }, 
					{
            path: "range",
            element: <KansliRange />,
						loader: kansliRootLoader
          }, 
					{
            path: "payment",
            element: <KansliPaymentHistory />,
						loader: kansliRootLoader
          },
					{
            path: "accounting",
            element: <KansliAccounting />,
						loader: kansliRootLoader
          }, 
					{
            path: "statistics",
            element: <KansliStatistics />,
						loader: kansliRootLoader
          }, 
					{
            path: "license-settings",
            element: <KansliLicenseSettings />,
						loader: kansliRootLoader
          }, 
					{
            path: "organizer",
            element: <KansliOrganizer />,
						loader: kansliRootLoader
          }, 
					{
            path: "series",
            element: <KansliSeries />,
						loader: kansliRootLoader
          }, 
					{
            path: "sport",
            element: <KansliSport />,
						loader: kansliRootLoader
          },  
					{
            path: "event-class",
            element: <KansliEventClass />,
						loader: kansliRootLoader
          },  
					{
            path: "event-type",
            element: <KansliEventType />,
						loader: kansliRootLoader
          },  
				]
      },
      {
        path: "user/:key_id",
        element: <UserRoot />,
        loader: userRootLoader,
				children: [
					{ index: true, element: <UserDetails />, loader: UserDetailsLoader, path: "profile" },
          {
            path: "events",
            element: <UserEvents />,
            loader: userEventsLoader
          },
          {
            path: "result",
            element: <UserResults />,
            loader: userResultsLoader
          },
          {
            path: "ranking",
            element: <UserRanking />,
            loader: userRankingLoader
          },
          {
            path: "license",
            element: <UserLicense />,
            loader: userLicenseLoader
          },
          {
            path: "associations",
            element: <UserAssociation />,
            loader: userAssociationLoader
          },
          {
            path: "family",
            element: <UserFamily />,
            loader: userFamilyLoader
          },
          {
            path: "roles",
            element: <UserRoles />,
            loader: userRolesLoader
          },
          {
            path: "settings",
            element: <UserSettings />,
            loader: userSettingsLoader
          },
					{
            path: "payments",
            element: <UserPayments />,
            loader: userPaymentsLoader
          },
        ]
      },
      {
        path: "event-overview/:id",
        element: <EventOverview />,
        loader: EventOverviewLoader,
      },
      {
        path: "event-anmalan/:id",
        element: <EventAnmalan />,
        loader: EventAnmalanLoader
      },

      {
        path: "system-settings",
        element: <SettingsHome />,
        //loader: EventOverviewLoader,
      },
      {
        path: "event-settings",
        element: <EventSettingsRoot />,
        //loader: EventSettingsRootLoader,
        children: [
          { index: true, element: <EventSettingsHome /> },
          {
            path: "categories",
            //element: <h1>fsfsfs</h1>
            element: <EventCategories />,
            loader: EventCategoriesLoader,

          },
          {
            path: "sportgrenar-klasser",
            //element: <h1>fsfsfs</h1>
            element: <EventSettingsDiscipline />,
            //loader: EventCategoriesLoader,

          },
          {
            path: "new",
            element: <EventNew />,
            //loader: EventNewLoader,
          },
        ],
      },
      {
        path: "events",
        element: <EventsRoot />,
        loader: EventsRootLoader,
        children: [
          { index: true, element: <MyEventCalendarAdmin />, loader: MyEventCalendarAdminLoader },
          {
            path: "new-edit/:id",
            element: <EventNew />,
            // action: EventNewAction,
            // loader: EventNewLoader,
          },
          {
            path: "new-edit-evenemang/:id",
            element: <EventNewEvenemang />,
            // action: EventNewAction,
            // loader: EventNewLoader,
          },

        ],
      },
      {
        path: "resultat-tv/:id",
        element: <EventResultTV />,
        // action: EventNewAction,
        //loader: EventProgramConfigLoader,
      },     
      {
        path: "event/:id",
        element: <EventRoot />,
        loader: EventRootLoader,
        children: [
          {
            index: true,
            element: <EventOverviewAdmin />,
            loader: EventOverviewAdminLoader
          },
          {
            path: "lag-config",
            element: <EventLagConfig />,
            // action: EventNewAction,
            //loader: EventLagConfigLoader,
          },
          {
            path: "lag-anmalan",
            element: <EventLagAnmalan />,
            // action: EventNewAction,
            //loader: EventLagConfigLoader,
          },          
          // 
          {
            path: "sportgrenar-config",
            element: <EventSportgrenarConfig />,
            // action: EventNewAction,
            loader: EventSportgrenarConfigLoader,
          },
          {
            path: "deltagare-config",
            element: <EventEditDeltagare />,
            // action: EventNewAction,
            //loader: EventLagConfigLoader,
          },  
          {
            path: "program-config",
            element: <EventProgramConfig />,
            // action: EventNewAction,
            loader: EventProgramConfigLoader,
          }, 
          {
            path: "startlista-config",
            element: <EventStartlista />,
            // action: EventNewAction,
            //loader: EventProgramConfigLoader,
          }, 
          {
            path: "resultat",
            element: <EventResult />,
            // action: EventNewAction,
            //loader: EventProgramConfigLoader,
          },     
          {
            path: "documents",
            element: <EventDocuments />,
            // action: EventNewAction,
            loader: EventDocumentsLoader,
          },        
          {
            path: "reports",
            element: <EventReports />,
            // action: EventNewAction,
            loader: EventReportsLoader,
          },           
          {
            path: "edit",
            element: <EventNew />,
            // action: EventNewAction,
            // loader: EventNewLoader,
          },
          {
            path: "deltagare",
            element: <h1>ghjgjhgjgjreete</h1>,
          },

          {
            path: "edit-stepper",
            element: <EventEditStepper />,
            children: [
              {
                index: true,
                element: <EventNew />,
                // action: EventNewAction,
                // loader: EventNewLoader,
              },
              {
                path: "start",
                element: <EventNew />,
                // action: EventNewAction,
                // loader: EventNewLoader,
              },
              {
                path: "gren-klass",
                element: <EventEditSportgrenar />,
              },
              {
                path: "plats",
                element: <h1>plats</h1>,
              },
              {
                path: "betalning",
                element: <EventEditBetalning />,
              },
              {
                path: "dokument",
                element: <EventEditDokument />,
              },
              {
                path: "lag",
                element: <EventEditLag />,
              },
              {
                path: "extra",
                element: <EventEditExtra />,
              },
            ]
          },
          {
            path: "gren-klass",
            element: <EventEditSportgrenar />,
            // loader: EventEditSportgrenarLoader,
          },
          {
            path: "betalning",
            element: <EventEditBetalning />,
          },
          {
            path: "edit-stepper-2",
            element: <EventEditStepper2 />,
          },

        ],
      },
      {
        path: "/gren",
        element: <GrenHome />,
        loader: GrenHomeLoader,
        /* children: [
          { index: true, element: <TeamHome /> },
          {
            path: "overview",
            element: <TeamHome />,
            loader: TeamHomeLoader,
          },  
        ],   */
      },
      {
        path: "/settings",
        element: <SettingsRoot />,
        loader: SettingsRootLoader,
        children: [
          { index: true, element: <SettingsHome /> },
          {
            path: "overview",
            element: <SettingsHome />,
            //loader: EventOverviewLoader,
          },
        ],
      },
    ],
  },
  {
    path: "error",
    element: <ErrorPage />,
  },  
  {    path: "*",
    element: <ErrorBoundary> <NotFound404 /></ErrorBoundary> ,
  },
 
]);



function App() {
  // const [basicOpen, setBasicOpen] = useState(true);
  console.log(process.env.NODE_ENV, window.location.protocol, window.location.hostname, window.location.port);
  return (


    <RouterProvider router={router} />

   

  );
}

export default App;