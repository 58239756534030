import { GetLatestStartDate, GetEndDate } from "../utils/GetLicenseDates"
import { fetch_post } from "../../../../services/common";
import CompareDates from "../utils/CompareDates";

export async function AddLicenseMulti(user_arr){

	const arr = []

	user_arr.map(user => {
		arr.push({	db_user_id : user.id,
								db_licens_id : 4,
								db_start_date : GetLatestStartDate(user.licenser),
								db_end_date : GetEndDate(user.licenser),
								db_expired_date : GetEndDate(user.licenser),
								db_status: CompareDates(GetLatestStartDate(user.licenser),GetEndDate(user.licenser))
							}) 
	})

	const jsonObj = {
		action: "add_license_multi",
		user_arr: arr,
	}
	
	const response = await fetch_post("app/user/do_submit/", {
		jsonObj
	});
	
	if (!response.ok) {
		//throw new Error(
		 // `This is an HTTP error: The status is ${response.status}`
		//);
	}    
	const obj = await response.json();
	return  obj;
}