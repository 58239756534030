import { fetch_post } from "../../../../services/common";

export async function AddSportclass(parent_id, form){
	
	const response = await fetch_post("app/sportclass/do_submit/" + parent_id, {
    action: "add_sportclass",
		db_def_klass_typer_id: form.def_klass_typer_id,
    db_name: form.name,
		db_sort_order: form.sort_order,
		db_age_min: form.age_min,
		db_age_max: form.age_max,
		db_gender: form.gender,
		db_results_min: form.results_min,
		db_results_max: form.results_max,
		db_results_to_count: form.results_to_count
	});
	
	return  response;
}