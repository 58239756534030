import jwt_decode from "jwt-decode";
import { fetch_get } from "../../../../services/common";

export async function fetch_pay(body){
    const headers = new Headers({
        "Content-Type": "application/json; charset=utf-8",
        "Authorization": "Bearer 760b9687ee4a6035181773bf7461152e6351caf572e6996f8356383576fe66d4",
        "User-Agent": "swedbankpay-sdk-dotnet/3.0.1",
        "Accept": "application/problem+json; q=1.0, application/json; q=0.9"
    });

    const response = await fetch("https://dev.svsf-ta.se/api/app/swedbankpay/", {
    //headers: headers,
    method: 'POST',
    body: JSON.stringify(body)

    });
    
    if (!response.ok) {
      //throw new Error(
       // `This is an HTTP error: The status is ${response.status}`
      //);
    }    
    const obj = await response.json();
    return  obj;
  }

	export async function get_order(key_id){

    const response = await fetch("https://dev.svsf-ta.se/api/app/pay/data/" + key_id, {
    //headers: headers,
    method: 'GET'

    });
    
    if (!response.ok) {
      //throw new Error(
       // `This is an HTTP error: The status is ${response.status}`
      //);
    }    
    const obj = await response.json();
    return  obj;
  }

  export async function new_order(){
    
    const data = localStorage.getItem("token");
    var decoded = "";
    if (data) {
      decoded = jwt_decode(data);
    }

    const user = await fetch_get("app/user/all/" + decoded.kid);

    const response = await fetch("https://dev.svsf-ta.se/api/app/pay/do_submit/new", {
    //headers: headers,
    method: 'POST',
    body: JSON.stringify({
      "action": "add_edit",
      "db_user_id": user.data.id}
    )
      

    });
    
    if (!response.ok) {
      //throw new Error(
       // `This is an HTTP error: The status is ${response.status}`
      //);
    }    
    const obj = await response.json();
    return  obj;
  }

  export async function update_order(key_id,paymentResponse,paymentRequest){

    const orderRequest = paymentRequest.message_body.paymentorder;
    const orderResponse = paymentResponse.data.paymentOrder;
    const payment_key = orderResponse.id.toString().replace("/psp/paymentorders/","");
    const orderItems = paymentRequest.message_body.paymentorder.orderItems;
    const metadata = orderRequest.metadata.data;
		console.log(orderResponse)

    orderItems.forEach((orderItem,index) => {
      const userID = metadata[index].userID;
      add_order_item(key_id,orderItem,userID);
    });

    const response = await fetch("https://dev.svsf-ta.se/api/app/pay/do_submit/" + key_id, {
    //headers: headers,
    method: 'POST',
    body: JSON.stringify({
      "action": "add_edit",
      "db_amount": orderResponse.amount,
      "db_vat_amount": orderResponse.vatAmount,
      "db_description": orderResponse.description,
      "db_metadata": JSON.stringify(orderRequest.metadata),
      "db_operation": orderResponse.operation,
      "db_order_ref": orderRequest.payeeInfo.orderReference,
      "db_payee_name": orderRequest.payeeInfo.payeeName,
      "db_payee_ref": orderRequest.payeeInfo.payeeReference,
      "db_payment_key": payment_key,
      "db_status": orderResponse.status}
    )

    });
    
    if (!response.ok) {
      //throw new Error(
       // `This is an HTTP error: The status is ${response.status}`
      //);
    }    
    const obj = await response.json();
    return  obj;
  }
	
  export async function set_payment_method(key_id,method){

    const response = await fetch("https://dev.svsf-ta.se/api/app/pay/do_submit/" + key_id, {
    //headers: headers,
    method: 'POST',
    body: JSON.stringify({
      "action": "add_edit",
      "db_payment_method": method}
    )

    });
    
    if (!response.ok) {
      //throw new Error(
       // `This is an HTTP error: The status is ${response.status}`
      //);
    }    
    const obj = await response.json();
    return  obj;
  }

  export async function update_processed_order_status(key_id,status){

    const response = await fetch("https://dev.svsf-ta.se/api/app/pay/do_submit/" + key_id, {
    //headers: headers,
    method: 'POST',
    body: JSON.stringify({
      "action": "add_edit",
      "db_status": status}
    )

    });
    
    if (!response.ok) {
      //throw new Error(
       // `This is an HTTP error: The status is ${response.status}`
      //);
    }    
    const obj = await response.json();
    return  obj;
  }

  export async function add_order_item(key_id,order_item,user_id){

    const response = await fetch("https://dev.svsf-ta.se/api/app/pay/do_submit/" + key_id, {
    //headers: headers,
    method: 'POST',
    body: JSON.stringify({
      "action": "add_edit_items",
      "order_items": [
            {
                "db_reference": order_item.reference,
                "db_amount": order_item.amount,
                "db_class": order_item.class,
                "db_description": order_item.description,
                "db_name": order_item.name,
                "db_quantity": order_item.quantity,
                "db_reciever_user_id": user_id,
                "db_type": order_item.type,
                "db_unit_price": order_item.unitPrice,
                "db_vat_amount": order_item.vatAmount               

            }]}
    )
      

    });
    
    if (!response.ok) {
      //throw new Error(
       // `This is an HTTP error: The status is ${response.status}`
      //);
    }    
    const obj = await response.json();
    return  obj;
  }

